import React, { useState } from 'react'
import { add } from 'date-fns'
import { uuidv7 } from 'uuidv7'
import { View } from 'react-native'
import { Form } from '../../../components/common/form/form'
import { useDispatch, useSelector } from 'react-redux'
import { newDreamForm } from '../../../forms/dream-form'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../contexts/auth-context'
import { setDreamFieldsLoading } from '../../../ducks/ui/ui'
import { addFastDream } from '../../../ducks/dream-tag/dream-tag'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import { newFastDream } from '../../../ducks/dream-tag/modules/dream-helpers'
import { ArtStyleKey, EntryType } from '../../../../../api/frontend-types'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { regenerateImage } from '../../../ducks/dream-tag/functions/image-functions'
import {
  FastDream,
  MediaObject_media_object_read,
} from '../../../../../api/_openapi'
import {
  saveDraftDream,
  saveDream,
  updateDream,
} from '../../../ducks/dream-tag/thunks/dream-thunks'
import {
  cap,
  formatDateForDatabase,
  isUuidv7,
} from '../../../modules/strings/string-helpers'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import {
  selectCurrentUserProfile,
  selectDefaultArtStyle,
  selectIsElsewhereTeam,
  selectNoImageArtStyleId,
} from '../../../ducks/user/user'

export type DreamAddNavigationProp = RootStackNavigationProp<'PrivateDreamAdd'>

export default function DreamAdd({
  navigation,
}: RootStackScreenProps<'PrivateDreamAdd'>) {
  // STATE
  const [dreamType, setDreamType] = useState<EntryType>('dream')

  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()
  const dispatch = useDispatch<any>()

  // SELECTORS
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const privateGroupId = useSelector(selectPrivateGroupId)
  const currentUserProfile = useSelector(selectCurrentUserProfile)
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)
  const defaultArtStyleId = useSelector(selectDefaultArtStyle)
  const noArtStyleId = useSelector(selectNoImageArtStyleId)

  // FUNCTIONS

  // Add image to the dream once the dream is saved
  const saveImgToDream = (
    image: MediaObject_media_object_read,
    savedDream: FastDream,
    privateGroupId: string,
    dreamId: string,
  ) => {
    const updatedDream: FastDream = {
      ...savedDream,
      imageId: image.id || '',
      imageUrl: image.url || '',
    }
    dispatch(updateDream(privateGroupId, dreamId, updatedDream, false)).then(
      () => {
        dispatch(
          setDreamFieldsLoading({
            dreamId: dreamId,
            fields: ['imageId'],
            loading: false,
          }),
        )
      },
    )
  }

  // ON SUBMIT
  const onSubmitForm = (data: any) => {
    if (!privateGroupId) {
      return
    }

    // Art style
    // If the user has entered a custom prompt, this will be free text
    // If the user has selected a prompt, this will be the id of the prompt
    const artStyle: ArtStyleKey = data.artStyle || defaultArtStyleId
    const isCustomPrompt = !isUuidv7(artStyle)
    const isDraft = Boolean(data.isDraft)
    const shouldGenerateImage = !(artStyle === noArtStyleId) && !isDraft
    const customPrompt = isCustomPrompt ? artStyle : undefined
    const artStyleId = isCustomPrompt ? defaultArtStyleId : artStyle

    // When you send a id, the server will return it as the id of the dream
    const id = uuidv7()
    const baseDream = {
      id: id,
      title: data.title || '',
      type: dreamType,
      description: data.description || 'Error: No description provided.',
      date: data.date || formatDateForDatabase(new Date()),
      displayDate: data.displayDate,
      userId: authData?.user,
      note: data.note || '',
      isDraft,
      ...data.place,
    } as Partial<FastDream>

    // 1) Dispatch the dream to redux
    const dreamToAddToStore: Partial<FastDream> = {
      ...baseDream,
    }
    dispatch(addFastDream(newFastDream(dreamToAddToStore)))

    // 2) Set image loading (unless the dream is a draft or user doesn't want images)
    if (shouldGenerateImage) {
      dispatch(
        setDreamFieldsLoading({
          dreamId: id,
          fields: ['imageId'],
          loading: true,
        }),
      )
    }

    // 3) Navigate to the page
    // If it's a draft, navigate to the drafts page
    if (isDraft) {
      navigation.navigate('MainTabs', {
        screen: 'MainStack',
        params: { screen: 'PrivateDraftsFeed' },
      })
    } else {
      if (dreamType === 'dream') {
        navigation.replace('MainTabs', {
          screen: 'MainStack',
          params: {
            screen: 'PrivateDreamView',
            params: {
              id: id,
              tab: 'dream',
            },
          },
        })
      } else if (dreamType === 'journal-entry') {
        navigation.replace('MainTabs', {
          screen: 'MainStack',
          params: {
            screen: 'PrivateDiaryView',
            params: {
              id: id,
            },
          },
        })
      }
    }

    // 4) Save the dream
    const dreamToSend = {
      ...baseDream,
    }

    if (isDraft || dreamType === 'journal-entry') {
      dispatch(saveDraftDream(privateGroupId, dreamToSend))
    } else {
      dispatch(
        saveDream(privateGroupId, id, dreamToSend, subscriptionStatus, false),
      ).then((savedDream: FastDream) => {
        // If the user doesn't want images, we're done
        if (artStyle === noArtStyleId) {
          dispatch(
            setDreamFieldsLoading({
              dreamId: id,
              fields: ['imageId'],
              loading: false,
            }),
          )
          return
        }

        // Else, generate the image
        const basicDream = {
          dreamId: id,
          title: data.title,
          description: data.description,
        }

        regenerateImage(
          basicDream,
          (img) => {
            saveImgToDream(img, savedDream, privateGroupId, id)
          },
          (loading) => {
            dispatch(
              setDreamFieldsLoading({
                dreamId: id,
                fields: ['imageId'],
                loading,
              }),
            )
          },
          false,
          null,
          artStyleId,
          subscriptionStatus,
          customPrompt,
        )
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <Form
        name={'newDreamForm'}
        form={newDreamForm}
        onSubmit={onSubmitForm}
        headerTitle={
          dreamType === 'dream'
            ? t('addDreamPage.header')
            : t('addDreamPage.addDiaryEntry')
        }
        showHeaderOptions={isElsewhereTeam}
        headerOptions={[
          {
            label: cap(t('common.dream_plural', { count: 1 })),
            onPress: () => {
              setDreamType('dream')
            },
            closesMenu: true,
            iconKey: dreamType === 'dream' ? 'check' : undefined,
          },
          {
            label: t('common.diary'),
            onPress: () => {
              setDreamType('journal-entry')
            },
            iconKey: dreamType === 'journal-entry' ? 'check' : undefined,
            closesMenu: true,
          },
          {
            label: t('common.cancel'),
            onPress: () => {},
            closesMenu: true,
          },
        ]}
        prepopulateValues={{
          date: formatDateForDatabase(
            add(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
              ),
              { days: currentUserProfile?.dreamDateOffset || 0 },
            ),
          ),
          title: '',
          description: '',
          note: '',
          artStyle: defaultArtStyleId,
        }}
        confirmCancelMessage={t('dialog.confirmCancelDream.description')}
        checkOnCancelFields={['description', 'title', 'note']}
        showScanIfFormIsNotValid={true}
      />
    </View>
  )
}
