import React, { useState } from 'react'
import i18n from 'i18next'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from '../../../ducks/root-reducer'
import {
  selectCurrentUserProfile,
  selectUser,
  selectUserIsPremium,
} from '../../../ducks/user/user'
import { undecorateId } from '../../../../../api/decorate-ids'
import {
  updateUser,
  upsertDreamerProfile,
} from '../../../ducks/user/thunks/user-thunks'
import {
  selectAllInterpretationStyleOptionsForForm,
  selectArtStyleOptions,
  selectInterpretationOptions,
  selectInterpretationStyles,
} from '../../../ducks/ui/ui'
import { DreamerProfileEditType } from '../../../../../api/frontend-types'
import {
  Form,
  FormDetails,
  FormFieldDetails,
} from '../../../components/common/form/form'
import { RootStackScreenProps } from '../../../navigation/types'

export default function DreamSettingsEdit({}: RootStackScreenProps<'PrivateDreamSettingsEdit'>) {
  const [loading, setLoading] = useState(false)

  // SELECTORS
  const user = useSelector(selectUser)
  const userIsPremium = useSelector(selectUserIsPremium)
  const currentUserProfile = useSelector(selectCurrentUserProfile)
  const interpretationOptions = useSelector(selectInterpretationOptions)
  const interpretationStyles = useSelector(selectInterpretationStyles)
  const [selectedInterpretationStyle, setSelectedInterpretationStyle] =
    useState<string | null>(undecorateId(user?.interpretationStyleId || 'null'))

  function createObjectFromString(str: any) {
    if (!str) return null
    return JSON.parse('{' + str.slice(1, -1) + '}')
  }

  const userInterpretationOptions: { [key: string]: any } =
    // temp fix to parse the user.interpretationOptions string into an object,
    createObjectFromString(user?.interpretationOptions) || {}

  // HOOKS
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()

  // VARS
  const { artStyleId, interpretationStyleId, hemisphere } = user || {}
  const { dreamDateOffset } = currentUserProfile || {}

  const onSubmitForm = async (data: any) => {
    setLoading(true)

    const userPatch = {
      artStyleId: data.artStyle,
      hemisphere: data.hemisphere === 'null' ? null : data.hemisphere,
      interpretationOptions: data.interpretationOptions,
      interpretationStyleId: data.interpretationStyle,
    }
    const dreamerProfilePatch = {
      dreamDateOffset: Number(data.dreamDateOffset || 0),
    } as DreamerProfileEditType

    const performUpdate = async () => {
      await dispatch(
        upsertDreamerProfile(
          currentUserProfile?.id || '',
          dreamerProfilePatch,
          user?.id || '',
          false,
        ),
      )
      await dispatch(updateUser(user?.id || '', userPatch))
    }

    performUpdate().finally(() => setLoading(false))
  }

  const fields: FormFieldDetails[] = [
    {
      label: 'userForm.defaultArtStyle',
      name: 'artStyle',
      type: 'select',
      optionsSelector: selectArtStyleOptions(),
      maintainSortOrder: true,
    },
    {
      label: 'userForm.defaultInterpretationStyle',
      description: 'userForm.defaultInterpretationStyle.description',
      name: 'interpretationStyle',
      type: 'select',
      optionsSelector: selectAllInterpretationStyleOptionsForForm,
      onChange: (value: any) => {
        setSelectedInterpretationStyle(value)
      },
      maintainSortOrder: true,
    },
  ]

  const hemisphereField: FormFieldDetails = {
    label: 'userForm.defaultHemisphere',
    description: 'userForm.defaultHemisphere.description',
    name: 'hemisphere',
    type: 'select',
    options: [
      { value: 'n', label: t('common.northern') },
      { value: 's', label: t('common.southern') },
      { value: 'null', label: t('common.auto') },
    ],
  }

  const optionDefaults: { [key: string]: string } = {}

  //add interpretation options
  interpretationOptions.forEach((option) => {
    const styleKey = option.style || ''
    const styleId = interpretationStyles.find(
      (style) => style.key === styleKey,
    )?.id
    const options: any = option.options || []
    const optionNames = Object.keys(options)
    optionNames.forEach((optionName) => {
      fields.push({
        label: 'userForm.interpretationOptions.' + styleKey + '.' + optionName,
        name: 'interpretationOptions.' + styleKey + '.' + optionName,
        type: 'select',
        hidden: styleId !== selectedInterpretationStyle,
        options: options[optionName].options.map((option: any) => {
          return {
            value: option,
            label: t(option),
          }
        }),
        maintainSortOrder: true,
      })

      if (
        userInterpretationOptions[styleKey] &&
        userInterpretationOptions[styleKey][optionName]
      ) {
        optionDefaults['interpretationOptions.' + styleKey + '.' + optionName] =
          userInterpretationOptions[styleKey][optionName]
      } else {
        optionDefaults['interpretationOptions.' + styleKey + '.' + optionName] =
          options[optionName].default
      }
    })
  })

  //add all option fields, then add the dream date field
  // fields.push(dreamDateField)
  if (userIsPremium) {
    fields.push(hemisphereField)
  }

  const userSettingsForm: FormDetails = {
    pages: [
      {
        fields,
      },
    ],
  }

  return (
    <Form
      name={'dreamSettingsForm'}
      form={userSettingsForm}
      onSubmit={onSubmitForm}
      loading={loading}
      prepopulateValues={{
        artStyle: undecorateId(artStyleId),
        interpretationStyle: undecorateId(interpretationStyleId),
        hemisphere: hemisphere === null ? 'null' : hemisphere,
        dreamDateOffset: String(dreamDateOffset),
        ...optionDefaults,
      }}
      headerTitle={t('settings.dreamSettings')}
    />
  )
}
