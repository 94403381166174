import React from 'react'
import { SANS_BOLD_FONT_WEIGHT } from '../../../constants/constants'
import { LINE_WIDTH } from '../../../constants/constants'
import { Row } from '../row/row'
import { SansText } from '../copy/text-sans'

export const renderHeading = ({
  heading,
  color,
}: {
  heading: string
  color: string
}) => (
  <Row
    justifyContent={'center'}
    alignItems={'center'}
    p={2}
    mb={4}
    borderBottomWidth={LINE_WIDTH}
    borderBottomColor={color}
  >
    <SansText
      fontWeight={SANS_BOLD_FONT_WEIGHT}
      color={color}
      textTransform={'uppercase'}
      textAlign={'center'}
    >
      {heading}
    </SansText>
  </Row>
)
