import React, { useEffect, useState } from 'react'
import { useColorModeValue } from 'native-base'
import { useTranslation } from 'react-i18next'
import { DateRangeWithKey } from '../../../../api/frontend-types'
import { X_TICKS_PER_RANGE } from '../../config/frontend-config'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { FEW } from '../../i18n/config'
import { findFocusedRoute, useNavigationState } from '@react-navigation/core'
import { formatDateForDatabase } from '../../modules/strings/string-helpers'
import { TagDateCount, TagDateCountService } from '../../../../api/_openapi'
import { dreamsToChartData } from '../../components/composite/chart/helpers/dreams-to-chart-data'
import {
  ChartPadding,
  TimelineChart,
} from '../../components/composite/chart/timeline-chart'

type DreamsPerDayChartProps = {
  dateRangeWithKey: DateRangeWithKey
}

export const LucidDreamsChart = ({
  dateRangeWithKey,
}: DreamsPerDayChartProps) => {
  // HOOKS
  const { t } = useTranslation()
  const userLineColor = useColorModeValue(BLACK, WHITE)
  const user = useSelector(selectUser)
  const navState = useNavigationState((state) => state)
  const currentRoute: any = findFocusedRoute(navState)
  const groupIdFromRoute =
    currentRoute?.params?.params?.params?.groupId ||
    currentRoute?.params?.params?.groupId ||
    currentRoute?.params?.groupId

  const activeGroupId = groupIdFromRoute || user?.userGroupId
  const [tagDateCount, setTagDateCount] = useState<TagDateCount>({
    dateCounts: {},
    total: 0,
    id: '',
  })

  // EFFECTS
  useEffect(() => {
    if (!activeGroupId) {
      return
    }

    const startDate = dateRangeWithKey.range.start
      ? formatDateForDatabase(dateRangeWithKey.range.start)
      : undefined
    const endDate = dateRangeWithKey.range.end
      ? formatDateForDatabase(dateRangeWithKey.range.end)
      : undefined

    TagDateCountService.tagDreamDates(
      activeGroupId,
      startDate,
      endDate,
      undefined,
      'Lucid',
      'DREAM_TYPE',
    ).then((data) => {
      setTagDateCount(data)
    })
  }, [activeGroupId, dateRangeWithKey.key])

  // VARS
  const userLanguage = user?.languageCode || 'en-US'
  const chartPadding: ChartPadding = {
    left: 50,
    right: 30,
    top: 30,
  }
  const chartLegend = t('chartsPage.dreamsPerPeriod.legend')
  const chartData = dreamsToChartData(tagDateCount, userLineColor, chartLegend)

  return (
    <TimelineChart
      chartData={chartData}
      loading={false}
      chartPadding={chartPadding}
      tickCount={X_TICKS_PER_RANGE[dateRangeWithKey.key]}
      chartTitle={t('dreamType.lucid_plural', { count: FEW })}
      dateRangeWithKey={dateRangeWithKey}
      userLanguage={userLanguage}
      bucketizeMode={'sum'}
    />
  )
}
