import React from 'react'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import { Box } from '../box/box'
import { ElsewhereIcon } from '../elsewhere-icon/elsewhere-icon'
import { DARK_GOLD, WHITE } from '../../../constants/ui-constants'
import { Size } from '../../../core/theme'

type PremiumBadgeProps = InterfaceBoxProps & {
  color?: string
  size?: Size
}

export const PremiumBadge = ({
  backgroundColor,
  borderColor,
  color,
  borderWidth,
  padding,
  size,
  ...rest
}: PremiumBadgeProps) => {
  const iconColor = color || WHITE
  const iconBackgroundColor = backgroundColor || DARK_GOLD
  const iconBorderColor = borderColor || WHITE
  const iconBorderWidth = borderWidth || 1
  const iconPadding = padding || 1
  const iconSize = size || '2xs'

  return (
    <Box
      backgroundColor={iconBackgroundColor}
      borderRadius={'full'}
      padding={iconPadding}
      borderColor={iconBorderColor}
      borderWidth={iconBorderWidth}
      {...rest}
    >
      <ElsewhereIcon iconKey={'gem'} size={iconSize} color={iconColor} />
    </Box>
  )
}
