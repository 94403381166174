import React, { useState, useMemo, useEffect } from 'react'
import FeedItemSymbolInterpretation from '../../components/composite/feed/feed-item-symbol-interpretation'
import { Dimensions, View, ListRenderItem, Platform } from 'react-native'
import {
  CountedFastTag,
  FastDream,
  FastDreamService,
  FastGroup,
  FastTagDescription,
  TagSummaryStats,
  TagSummaryStatsService,
} from '../../../../api/_openapi'
import { RouteProp } from '@react-navigation/native'
import { useSelector } from '../../ducks/root-reducer'
import {
  selectUser,
  selectUserIsPremium,
  selectUserIsSupporter,
} from '../../ducks/user/user'
import { TabView } from '../../components/layout/tab-view'
import { Tabs, TabProps } from '../../components/layout/tab-view-tabs'
import { Loading } from '../../components/layout/loading'
import { TagPageHeader } from './header'
import { useTranslation } from 'react-i18next'
import { capitalize } from '../../modules/strings/string-helpers'
import { TagChartTab } from './tag-chart-tab'
import { FEW } from '../../i18n/config'
import { selectSubscriptionStatus } from '../../ducks/subscription/subscription'
import { EmptyFeedPage } from '../../components/composite/feed/page-empty-feed'
import { InsightTab } from '../Insights/InsightsMain/InsightTab'
import {
  MainStackNavigationProp,
  MainStackParamList,
} from '../../navigation/types'
import FeedItemDream from '../../components/composite/feed/feed-item-dream'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { FastTag } from '../../../../api/_openapi'
import { useAuth } from '../../contexts/auth-context'
import { CentredListEmptyComponent } from '../../components/layout/centred-list-empty-component'
import { BLACK, WHITE } from '../../constants/ui-constants'
import { useColorModeValue } from 'native-base'
import { SansText } from '../../components/common/copy/text-sans'
import Autolink from 'react-native-autolink'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { Row } from '../../components/common/row/row'
import { IconGem } from '../../assets/react-native-svg/icons/icons-ui'
import { title } from '../../modules/strings/title-case'
import { SANS_BOLD_FONT_WEIGHT } from '../../constants/constants'
import { Center } from '../../components/common/center/center'

export type TagViewNavigationProp = MainStackNavigationProp<'PrivateTagView'>
export type TagViewRouteProp = RouteProp<MainStackParamList, 'PrivateTagView'>

type TagPageTabName = 'Dreams' | 'Insights' | 'Dictionary' | 'Charts'

type TagViewComponentProps = {
  tag: FastTag | null
  symbolDictItems: FastTagDescription[]
  currentGroup: FastGroup | undefined
  showMap: boolean
  navigation: any
  route: any
}

export default function TagViewComponent({
  tag,
  symbolDictItems,
  showMap,
  currentGroup,
  navigation,
  route,
}: TagViewComponentProps) {
  const privateGroupId = useSelector(selectPrivateGroupId)
  // HOOKS
  const { t } = useTranslation()
  const { authData } = useAuth()
  const color = useColorModeValue(BLACK, WHITE)

  // SELECTORS
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const appUser = useSelector(selectUser)
  const userIsPremium = useSelector(selectUserIsPremium)
  const userIsSupporter = useSelector(selectUserIsSupporter)
  const [loading, setLoading] = useState(Boolean(true))
  const [summaryStats, setSummaryStats] = useState<TagSummaryStats>({
    id: '',
    tagType: 'NONE',
    tagName: '',
    numDreamsWithTag: 0,
    percent: 0,
    relatedTags: [],
  })

  // HELPER FUNCTIONS
  // Themes and symbols are translated
  const getTagLabel = (tag: FastTag | undefined): string => {
    if (!tag) {
      return t('common.tag_plural', { count: 1 })
    } else if (tag.type === 'THEME' || tag.type === 'MOON_PHASE') {
      return t(`${tag.name}`)
    } else if (tag.type === 'SYMBOL') {
      return t(`symbol.${tag.name}`)
    } else {
      return tag.name
    }
  }

  // VARS
  const isSymbolTag = tag?.type === 'SYMBOL'
  const isThemeTag = tag?.type === 'THEME'
  const isMoonPhaseTag = tag?.type === 'MOON_PHASE'
  const pageWidth = Dimensions.get('window').width
  const pageTitle = tag && getTagLabel(tag)
  const activeGroupId = currentGroup?.id || ''

  const tabsToRender: {
    [key in TagPageTabName]: boolean
  } = {
    Dreams: true,
    Insights: !isMoonPhaseTag,
    Dictionary: !isMoonPhaseTag && symbolDictItems.length > 0,
    // TODO: Enable charts tab
    Charts: !isMoonPhaseTag && userIsPremium,
  }

  const { tagName, numDreamsWithTag, percent, relatedTags } = summaryStats

  // MEMOS
  const tagDescriptionString = useMemo(() => {
    const showRelatedTags = relatedTags.length >= 4
    const showPercent = percent >= 1
    let tagLabel = tagName
    if (isThemeTag || isMoonPhaseTag) {
      tagLabel = t(`${tagName}`)
    } else if (isSymbolTag) {
      tagLabel = t(`symbol.${tagName}`)
    }
    const tagSummaryString = t(
      `${
        isMoonPhaseTag ? 'tagPage.moonPhase' : 'tagPage'
      }.tagDescription.numDreams`,
      {
        numDreamsWithTag,
        tagName: tagLabel,
      },
    )
    const tagPercentString = t(
      `${
        isMoonPhaseTag ? 'tagPage.moonPhase' : 'tagPage'
      }.tagDescription.percent`,
      {
        percent,
      },
    )
    const translatedRelatedTags = relatedTags.map(
      (relatedTag: CountedFastTag) => {
        if (relatedTag.type === 'THEME' || relatedTag.type === 'SYMBOL') {
          const prefix = relatedTag.type === 'THEME' ? '' : 'symbol.'
          return {
            ...relatedTag,
            name: t(`${prefix}${relatedTag.name}`),
          }
        } else {
          return relatedTag
        }
      },
    )
    const relatedTagsString = showRelatedTags
      ? t(
          `${
            isMoonPhaseTag ? 'tagPage.moonPhase' : 'tagPage'
          }.tagDescription.relatedTags`,
          {
            tagName: tagLabel,
            relatedTag1: translatedRelatedTags[0].name,
            relatedTag2: translatedRelatedTags[1].name,
            relatedTag3: translatedRelatedTags[2].name,
            relatedTag4: translatedRelatedTags[3].name,
          },
        )
      : ''
    let descString = ''
    if (showRelatedTags && showPercent) {
      descString = `${tagSummaryString} ${tagPercentString} ${relatedTagsString}`
    } else if (showPercent) {
      descString = `${tagSummaryString} ${tagPercentString}`
    } else if (showRelatedTags) {
      descString = `${tagSummaryString} ${relatedTagsString}`
    } else {
      descString = `${tagSummaryString}`
    }
    return descString
  }, [relatedTags])

  //load the tag summary stats
  useEffect(() => {
    if (tag && currentGroup) {
      TagSummaryStatsService.tagSummaryStats(tag.id, currentGroup.id)
        .then((stats: TagSummaryStats) => {
          setSummaryStats(stats)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [tag, currentGroup?.id])

  // RENDERERS
  const renderDreamItem: ListRenderItem<FastDream> = ({ item, index }) => {
    return appUser ? (
      <FeedItemDream
        key={item.id}
        route={route}
        dream={item}
        index={index}
        privateGroupId={privateGroupId}
        currentGroup={currentGroup}
        appUser={appUser}
        activeGroupId={activeGroupId}
      />
    ) : null
  }

  const renderTagDescriptionItem: ListRenderItem<FastTagDescription> = ({
    item,
    index,
  }) => {
    return (
      <FeedItemSymbolInterpretation
        route={route}
        tagDescription={item}
        index={index}
      />
    )
  }

  // TAB CONTENTS
  const DreamTabContents = () => {
    const [dreams, setDreams] = useState<FastDream[]>([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
      if (!activeGroupId) return
      FastDreamService.groupDreams(activeGroupId, tag?.id).then((dreams) => {
        setDreams(dreams)
        setLoading(false)
      })
    }, [activeGroupId, tag?.id])

    return (
      <Tabs.FlatList
        data={dreams}
        renderItem={renderDreamItem}
        keyExtractor={(item) => item.id as string}
        contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
        ListEmptyComponent={CentredListEmptyComponent(
          loading ? <Loading color={color} /> : <EmptyFeedPage h="100%" />,
        )}
      />
    )
  }

  const InsightTabContents = () =>
    !authData?.token ? (
      <EmptyFeedPage />
    ) : (
      <InsightTab
        route={route}
        tagId={tag?.id}
        user={appUser}
        noInsightsMsg={t('insightsPage.emptyInsightsSymbol')}
        showLocked={true}
        subscriptionStatus={subscriptionStatus}
      />
    )

  const DictionaryTabContents = () => (
    <Tabs.FlatList
      data={symbolDictItems}
      renderItem={renderTagDescriptionItem}
      keyExtractor={(item: FastTagDescription) => item.id}
      contentContainerStyle={Platform.OS === 'web' ? { flex: 1 } : undefined}
      ListEmptyComponent={CentredListEmptyComponent(<Loading color={color} />)}
    />
  )

  const ChartTabContents = () => {
    if (!tag || !currentGroup) {
      return null
    }

    return (
      <Tabs.ScrollView>
        <TagChartTab tag={tag} activeGroupId={currentGroup.id || ''} />
      </Tabs.ScrollView>
    )
  }

  // TABS
  const tabs: TabProps<string>[] = [
    {
      name: 'Dictionary',
      children: <DictionaryTabContents />,
      label: t('common.symbolDictionary'),
    },
    {
      name: 'Charts',
      children: <ChartTabContents />,
      label: t('common.charts'),
    },
    {
      name: 'Insights',
      children: <InsightTabContents />,
      label: capitalize(t('common.insight_plural', { count: FEW })),
    },
    {
      name: 'Dreams',
      children: <DreamTabContents />,
      label: t('common.dream_plural', { count: FEW }),
    },
  ].filter((tab) => tabsToRender[tab.name as TagPageTabName])

  if (!userIsSupporter && isMoonPhaseTag) {
    return (
      <PaddedContentArea>
        <Center>
          <Row alignItems="center" mb={5}>
            <IconGem size={'xs'} color={color} marginX={3} />
            <SansText fontWeight={SANS_BOLD_FONT_WEIGHT}>
              {title(t('common.premiumFeature'))}
            </SansText>
          </Row>
          <SansText>
            <Autolink
              text={t('subscriptions.subscribeToUnlock') as string}
              matchers={[
                {
                  pattern: /<linkTag>(.*)<\/linkTag>/g,
                  getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                  onPress: () => {
                    navigation.navigate('PrivateSubscriptionSettingsEdit', {
                      tab: 'premium',
                    })
                  },
                },
              ]}
              linkProps={{
                style: {
                  textDecorationLine: 'underline',
                },
              }}
            />
          </SansText>
        </Center>
      </PaddedContentArea>
    )
  }

  if (!tag || loading || !currentGroup) {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading />
      </View>
    )
  } else {
    return (
      <TabView
        header={() => (
          <TagPageHeader
            title={pageTitle || ''}
            fullTag={tag}
            tagSummary={tagDescriptionString}
            pageWidth={pageWidth}
            userCanSeeMap={showMap}
            isMoonPhaseTag={isMoonPhaseTag}
          />
        )}
        tabs={tabs}
        scenes={{
          Dictionary: DictionaryTabContents,
          Dreams: DreamTabContents,
          Insights: InsightTabContents,
          Charts: ChartTabContents,
        }}
      />
    )
  }
}
