import React, { useState, useMemo } from 'react'
import { useColorModeValue, Spinner } from 'native-base'
import { Dimensions, Keyboard, TouchableWithoutFeedback } from 'react-native'
import { InputField } from '../inputs/input-field'
import { FormFieldOption } from '../../../../../api/frontend-types'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../copy/text-sans'
import { Row } from '../row/row'
import { ScrollView } from '../scrollview/scrollview'
import { FormControl } from '../form-control/form-control'
import {
  IconCheck,
  IconPlus,
} from '../../../assets/react-native-svg/icons/icons'
import { Actionsheet } from './action-sheet'
import { renderHeading } from './action-sheet-heading'

export type ActionSheetOption = FormFieldOption & {
  icon?: JSX.Element
  creating?: boolean
}

// If searchable, a search input will be shown at the top of the menu
// Something like react-select
type ActionSheetMenuMultiProps = {
  options: ActionSheetOption[]
  isOpen: boolean
  onClose: () => void
  searchable?: boolean
  onOptionSelect: (option: ActionSheetOption) => void
  selectedOptions?: string[]
  heading?: string
  optionLoading?: string | null
  autoFocus?: boolean
  allowCreation?: boolean
}

export const ActionSheetOptionsMenuMulti = React.memo(
  ({
    options,
    onOptionSelect,
    searchable = false,
    onClose,
    isOpen,
    selectedOptions,
    heading,
    optionLoading,
    autoFocus = false,
    allowCreation = false,
  }: ActionSheetMenuMultiProps) => {
    // Get page height
    const pageHeight = Dimensions.get('window').height
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState<string>('')

    // Todo: replace with Fuse.js
    const filteredOptions = useMemo(() => {
      if (searchValue === '') {
        return options
      }

      const createNewOption: ActionSheetOption = {
        label: t('themes.createNew', { value: searchValue }),
        value: searchValue,
        icon: <IconPlus />,
        creating: true,
      }

      const filteredOptions = options.filter((option) => {
        return (
          option.label.toLowerCase().includes(searchValue.toLowerCase()) ??
          false
        )
      })

      //if the search value is not in the options, add a create new option
      if (allowCreation) {
        if (!filteredOptions.find((option) => option.label === searchValue)) {
          filteredOptions.unshift(createNewOption)
        }
      }

      return filteredOptions
    }, [searchValue, options])

    // If the menu is searchable, we want it to remain a fixed height
    // So it doesn't grow and shrink as the user types
    const height = searchable ? pageHeight * 0.7 : undefined

    const color = useColorModeValue(BLACK, WHITE)

    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <Actionsheet
          isOpen={isOpen}
          onClose={() => {
            setSearchValue('')
            onClose()
          }}
        >
          <Actionsheet.Content height={height}>
            {heading && renderHeading({ heading, color })}
            {searchable && (
              <FormControl>
                <InputField
                  placeholder={t('common.search')}
                  onChangeText={(text: string) => {
                    setSearchValue(text)
                  }}
                  value={searchValue}
                  m={4}
                  autoFocus={autoFocus}
                />
              </FormControl>
            )}
            <ScrollView width={'100%'} keyboardShouldPersistTaps="handled">
              {filteredOptions.map((option: ActionSheetOption) => {
                return (
                  <Actionsheet.Item
                    key={option.value}
                    onPress={() => {
                      onOptionSelect(option)
                    }}
                    startIcon={option.icon ? option.icon : undefined}
                    endIcon={
                      optionLoading && option.value === optionLoading ? (
                        <Spinner color={color} />
                      ) : selectedOptions?.includes(option.value) ? (
                        <IconCheck color={color} size={'xs'} />
                      ) : undefined
                    }
                    justifyContent="center"
                  >
                    <SansText
                      color={color}
                      style={{
                        lineHeight: 22, // Somehow this is needed to make the text vertically centered
                      }}
                    >
                      {option.label}
                    </SansText>
                  </Actionsheet.Item>
                )
              })}
            </ScrollView>
          </Actionsheet.Content>
        </Actionsheet>
      </TouchableWithoutFeedback>
    )
  },
)
