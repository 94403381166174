import React from 'react'
import { Keyboard, Platform } from 'react-native'
import { useDisclose, useColorModeValue } from 'native-base'
import { TagIcon } from './tag-icon'
import { ActionSheetOptionsMenu } from '../../common/action-sheet/action-sheet-options-menu'
import { TagButtonIcon } from './tag-icon-button'
import { TagType } from '../../../../../api/frontend-types'
import { Loading } from '../../layout/loading'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { useTranslation } from 'react-i18next'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import TagChip from './tag-chip'
import { FastTag } from '../../../../../api/_openapi'
import { useSelector } from '../../../ducks/root-reducer'
import { selectFriendByTagId } from '../../../ducks/friends/friends'
import i18n from '../../../i18n/i18nnext'
import {
  getLanguageForTag,
  getSansFont,
} from '../../../modules/language-helpers/language-helpers'
import { getTagI18nKey } from '../../../modules/tag-helpers/tag-helpers'

// !editable == passive
// editable and !editing == editable
// editable and editing == editing
export type TagState = 'passive' | 'editable' | 'editing' | 'read-only'

type TagPillProps = {
  tag: FastTag
  tagState?: TagState
  onDeleteTag?: () => void
  onChangeTagType?: (newType: TagType) => void
  onSaveTagName?: (newName: string) => void
  onChangeTagName?: (newLabel: string) => void
  onPassiveTagPress?: () => void
}

type TagCategoryLabels = Partial<Record<TagType, string>>

const TagOptions: TagCategoryLabels = {
  CHARACTER: 'tagType.character_plural',
  SETTING: 'tagType.setting_plural',
  EMOTION: 'tagType.emotion_plural',
  ACTION: 'tagType.action_plural',
  ANIMAL: 'tagType.animal_plural',
  OTHER: 'tagType.other_plural',
}

export const TagPill = React.memo(
  ({
    tag,
    tagState = 'passive',
    onDeleteTag,
    onChangeTagType,
    onSaveTagName,
    onChangeTagName,
    onPassiveTagPress,
  }: TagPillProps) => {
    const { name, type } = tag
    const { t } = useTranslation()
    const friend = useSelector(selectFriendByTagId(tag.id))

    const TagCategoryOptions = Object.entries(TagOptions).map(
      ([key, value]) => {
        const Icon = (
          <TagIcon
            friend={friend}
            iconKey={key as Exclude<TagType, 'MOON_PHASE'>}
            size={'sm'}
          />
        )
        return {
          label: t(value, { count: 1 }).toLocaleUpperCase(),
          value: key,
          icon: Icon,
        }
      },
    )

    // Tag action sheet
    const {
      isOpen: categorySelectIsOpen,
      onOpen: onOpenCategorySelect,
      onClose: onCloseCategorySelect,
    } = useDisclose()

    // Appearance
    const color = useColorModeValue(BLACK, WHITE)
    const invertedColor = useColorModeValue(WHITE, BLACK)
    const textColor = tagState === 'passive' ? invertedColor : color
    const backgroundColor = tagState === 'passive' ? color : invertedColor
    const borderColor = color

    // Symbols and themes always display in the user's language
    // So they should use the font for the user's language
    // Other tags should use the font for the tag's language
    const userLanguage = i18n.resolvedLanguage || 'en'
    const tagFontLanguage = getLanguageForTag(tag, userLanguage)
    const fontFamily = getSansFont(tagFontLanguage)

    const loading = type === 'NONE'

    const original = React.useRef(tag)

    const optionsHeading = t('editDreamPage.changeTagTypeHeader', {
      tagName: name,
    })

    const isReadOnly = tag.type === 'THEME' || tag.type === 'DREAM_TYPE'
    const displayLarger =
      Platform.OS !== 'web' &&
      tagState === 'editing' &&
      !isReadOnly &&
      tag.type !== 'COLOR'

    return (
      <React.Fragment key={`tag-chip-${tag.id}`}>
        <TagChip
          id={tag.id}
          onPress={
            tagState === 'passive' && onPassiveTagPress
              ? () => {
                  if (tagState === 'passive') {
                    onPassiveTagPress && onPassiveTagPress()
                  }
                }
              : undefined
          }
          isReadOnly={isReadOnly}
          onSaveTagName={(text: string) => {
            if (text === original.current.name) {
              return
            }
            onSaveTagName && onSaveTagName(text)
          }}
          onChangeTagName={(text: string) => {
            onChangeTagName && onChangeTagName(text)
          }}
          text={
            type === 'DREAM_TYPE' || type === 'THEME'
              ? t(getTagI18nKey(tag))
              : name
          }
          textStyle={{
            fontFamily: fontFamily,
            fontWeight: '200',
            color: textColor,
          }}
          style={{
            backgroundColor,
            borderColor,
          }}
          leftIcon={
            !loading ? (
              <TagButtonIcon
                tagState={isReadOnly ? 'passive' : tagState}
                friend={friend}
                iconKey={type as Exclude<TagType, 'MOON_PHASE'>}
                outlined={tagState === 'editing'}
                onPress={() => {
                  Keyboard.dismiss()
                  onOpenCategorySelect()
                }}
                size={displayLarger ? 'md' : 'sm'}
                p={0}
                m={0}
                iconStroke={textColor}
              />
            ) : (
              <Loading width={24} />
            )
          }
          editable={tagState === 'editing'}
          rightIcon={
            tagState === 'editing' && (
              <ButtonIcon
                iconKey="close"
                size={displayLarger ? 'xs' : '2xs'}
                variant={'ghost'}
                p={0}
                m={0}
                borderRadius={'full'}
                onPress={onDeleteTag}
                alignSelf="center"
              />
            )
          }
          size={displayLarger ? 'md' : 'sm'}
        />
        {categorySelectIsOpen && (
          <ActionSheetOptionsMenu
            options={TagCategoryOptions}
            isOpen={categorySelectIsOpen}
            onClose={onCloseCategorySelect}
            selectedOption={type}
            onOptionSelect={(option) => {
              const newTagType = option.value as TagType
              onChangeTagType && onChangeTagType(newTagType)
            }}
            heading={optionsHeading}
          />
        )}
      </React.Fragment>
    )
  },
)
