import React from 'react'
import { Icon, useColorMode } from 'native-base'
import { Path, Circle } from 'react-native-svg'
import { IconProps } from '../../../components/common/icon/icon'
import { Hemisphere, MoonPhase } from '../../../../../api/frontend-types'
import { LINE_WIDTH } from '../../../constants/constants'
import { MOON_PHASE_MAP } from '../../../constants/ui-constants'

export const MoonPhasePathMap: Record<MoonPhase, string> = {
  newMoon:
    'M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2',
  fullMoon:
    'M32 2C15.432 2 2 15.432 2 32c0 16.569 13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m0 58C16.537 60 4 47.465 4 32S16.537 4 32 4c15.465 0 28 12.536 28 28S47.465 60 32 60',
  // wi--moon-alt-third-quarter
  lastQuarter:
    'M32.002 2h-.004C15.431 2 2 15.432 2 32c0 16.569 13.431 30 29.998 30h.004C48.569 62 62 48.568 62 32S48.569 2 32.002 2M4 32C4 16.537 16.536 4 31.998 4c.244 0 .482.013.725.019c.748 4.341 1.279 15.229 1.279 27.981c0 12.754-.531 23.641-1.279 27.983c-.243.005-.481.017-.725.017C16.536 60 4 47.465 4 32',
  // wi--moon-alt-first-quarter
  firstQuarter:
    'M62 32C62 15.432 48.569 2.001 32.001 2H32C15.432 2 2 15.432 2 32s13.432 30 30 30h.001C48.569 61.999 62 48.568 62 32M32 60c-.241 0-.479-.012-.72-.019c-.748-4.34-1.279-15.228-1.279-27.981s.531-23.641 1.279-27.981c.241-.007.479-.019.72-.019c15.465 0 28 12.536 28 28S47.465 60 32 60',
  // wi--moon-alt-waxing-gibbous-3
  waxingGibbous:
    'M62 32C62 15.432 48.569 2 32.001 2H32C15.432 2 2 15.432 2 32s13.432 30 30 30h.001C48.569 62 62 48.568 62 32M32 60c-2.568 0-5.053-.353-7.415-1c-5.673-4.862-9.584-15.124-9.584-27s3.911-22.138 9.584-27c2.362-.647 4.847-1 7.415-1c15.465 0 28 12.536 28 28S47.465 60 32 60',
  // wi--moon-alt-waning-gibbous-3
  waningGibbous:
    'M32.001 2h.003h-.005C15.431 2 2 15.432 2 32s13.431 30 29.999 30h.005h-.003C48.568 62 62 48.568 62 32S48.568 2 32.001 2m-.002 58C16.537 60 4 47.463 4 32C4 16.536 16.537 4 31.999 4c2.569 0 5.056.353 7.42 1.001C45.091 9.863 49 20.125 49 32s-3.909 22.136-9.581 26.998A28 28 0 0 1 31.999 60',
  // wi--moon-alt-waxing-crescent-2
  waxingCrescent:
    'M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m7.417 56.999C45.089 54.138 49 43.876 49 32S45.089 9.862 39.417 5.001C51.28 8.252 60 19.104 60 32s-8.72 23.748-20.583 26.999',
  // wi--moon-alt-waning-crescent-5
  waningCrescent:
    'M32.001 2h-.003C15.431 2 2 15.432 2 32s13.431 30 29.998 30h.003C48.569 62 62 48.568 62 32S48.569 2 32.001 2M4 32C4 19.104 12.722 8.25 24.585 4.999C18.913 9.86 15.002 20.124 15.002 32s3.911 22.14 9.583 27.001C12.722 55.75 4 44.896 4 32',
}

export const IconMoonPhase = (
  props: IconProps & {
    iconKey: MoonPhase
    hemisphere?: Hemisphere
    forceColorModeValue?: 'light' | 'dark'
  },
) => {
  const { colorMode } = useColorMode()
  const hemisphere = props.hemisphere ?? 'Northern'
  const size = props.size ?? 30
  const { iconKey, forceColorModeValue, ...rest } = props

  const colorModeKey =
    MOON_PHASE_MAP[forceColorModeValue || colorMode || 'light'][iconKey]

  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 64 64"
      {...rest}
      style={[
        {
          transform: [
            { rotate: hemisphere === 'Southern' ? '180deg' : '0deg' },
          ],
        },
      ]}
    >
      {colorModeKey !== 'newMoon' ? (
        <Path fill="currentColor" d={MoonPhasePathMap[colorModeKey]} />
      ) : (
        <Circle cx="32" cy="32" r="30" fill="currentColor" />
      )}
    </Icon>
  )
}
