import React, { ReactNode } from 'react'
import i18n from 'i18next'
import { useColorModeValue } from 'native-base'
import { Loading } from '../../layout/loading'
import {
  BLACK,
  CREAM,
  DARKER_CREAM,
  DARKER_GOLD,
  LIGHT_BLACK,
  MID_GOLD,
  WHITE,
} from '../../../constants/ui-constants'
import { SansText } from '../copy/text-sans'
import { Platform } from 'react-native'
import {
  EXTRA_SMALL_FONT_SIZE,
  LARGE_FONT_SIZE,
  LINE_WIDTH,
  SMALL_FONT_SIZE,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import { Link } from '@react-navigation/native'
import {
  ElsewhereIconType,
  iconMap,
} from '../../../modules/ui-helpers/icon-map'
import { Row } from '../row/row'
import { Box } from '../box/box'
import { Button, InterfaceButtonProps } from '../nb-button/nb-button'
import { Size } from '../../../core/theme'
import ConditionalLink from '../../../utilities/ConditionalLink'

export type ButtonType = 'primary' | 'secondary' | 'subtle'
export type ButtonSize = 'xs' | 'sm' | 'md'
export type ButtonVariant = 'solid' | 'outline'

type ButtonPillProps = InterfaceButtonProps & {
  children: ReactNode
  type?: ButtonType
  isDisabled?: boolean
  buttonSize?: ButtonSize
  variant?: ButtonVariant
  isLoading?: boolean
  isLink?: boolean
  linkProps?: any
  endIconKey?: ElsewhereIconType
  borderWidth?: number
  fontWeight?: number
  textTransfrom?: 'capitalize' | 'lowercase' | 'none' | 'uppercase'
  fontSize?: number | Size
}

type PaddingValue = '0' | '1' | '2' | '3' | '4' | '5'

const fontSizeMap: Record<ButtonSize, number> = {
  xs: EXTRA_SMALL_FONT_SIZE,
  sm: SMALL_FONT_SIZE,
  md: LARGE_FONT_SIZE,
}

export const ButtonPill = ({
  children,
  type = 'primary',
  isDisabled = false,
  buttonSize = 'md',
  variant = 'solid',
  borderWidth,
  isLoading = false,
  width,
  endIcon,
  endIconKey,
  isLink,
  linkProps,
  fontWeight,
  onPress,
  textTransform = 'uppercase',
  ...rest
}: ButtonPillProps) => {
  // HOOKS
  const invertedColor = useColorModeValue(WHITE, BLACK)
  const subtleInvertedColor = useColorModeValue(DARKER_GOLD, CREAM)
  const bgColor = useColorModeValue(BLACK, WHITE)
  const subtleBgColor = useColorModeValue(CREAM, LIGHT_BLACK)

  // VARS
  const isRtl = i18n.dir() === 'rtl'
  const isPrimary = type === 'primary'
  const isSubtle = type === 'subtle'
  const isSmall = buttonSize === 'sm'
  const isOutline = variant === 'outline'
  const isWeb = Platform.OS === 'web'
  const fontSize = rest.fontSize

  if (borderWidth === undefined) {
    borderWidth = isOutline ? LINE_WIDTH : isPrimary ? 0 : LINE_WIDTH
  }

  // Bg color
  const bgFill = isSubtle ? subtleBgColor : bgColor

  // TODO - Make this clearer / in the theme
  const getTextColor = () => {
    if (isOutline) {
      return bgColor
    } else if (isPrimary) {
      return invertedColor
    } else if (isSubtle) {
      return subtleInvertedColor
    } else {
      return bgColor
    }
  }

  const textColor = getTextColor()
  // Isolate style
  const { style, ...otherProps } = rest

  // Padding
  const paddingX = getPaddingX(buttonSize, children, isLoading)

  // Icon
  let iconEnd = undefined
  if (endIconKey) {
    const ElsewhereIcon = iconMap[endIconKey]
    iconEnd = (
      <ElsewhereIcon
        color={isDisabled ? DARKER_CREAM : textColor}
        size={buttonSize}
      />
    )
  } else if (endIcon) {
    iconEnd = endIcon
  }

  // STYLE
  const styleParams = {
    bgColor: isOutline ? 'transparent' : bgFill,
    borderWidth,
    borderColor: isSubtle ? MID_GOLD : isOutline ? bgColor : undefined,
    borderRadius: 'full',
    paddingLeft: paddingX,
    paddingRight: paddingX,
    paddingTop: getTopPadding(buttonSize, isLoading),
    paddingBottom: getBottomPadding(buttonSize, isWeb, isLoading),
  }

  const styleObj = {
    width: width ? width : isSmall ? 'auto' : '100%',
    maxWidth: WEB_MAX_WIDTH,
    alignSelf: 'center',
    // @ts-ignore
    ...style,
  }

  const innerText = children ? (
    <SansText
      color={textColor}
      fontSize={fontSize || fontSizeMap[buttonSize]}
      fontWeight={fontWeight}
      textTransform={textTransform}
      style={{
        textAlign: 'center',
      }}
    >
      {children}
    </SansText>
  ) : null

  const linkContents = (
    <Row {...styleParams} style={styleObj} alignItems={'center'}>
      {iconEnd && isRtl ? iconEnd : null}
      <Row alignItems={'center'} flexGrow={1} justifyContent={'center'}>
        <Box>{innerText}</Box>
      </Row>
      {iconEnd && !isRtl ? iconEnd : null}
    </Row>
  )

  if (!isLink) {
    return (
      <Button
        {...styleParams}
        isDisabled={isDisabled}
        endIcon={isRtl ? undefined : iconEnd}
        leftIcon={isRtl ? iconEnd : undefined}
        spinner={
          <Loading
            color={textColor}
            width={isSmall ? SMALL_FONT_SIZE * 2 : LARGE_FONT_SIZE * 1.8}
          />
        }
        _pressed={{
          opacity: 0.5,
        }}
        variant={variant}
        isLoading={isLoading}
        onPress={onPress}
        {...otherProps}
        style={styleObj}
        size={buttonSize}
      >
        {innerText}
      </Button>
    )
  } else {
    return (
      <>
        {isDisabled ? (
          <> {linkContents}</>
        ) : (
          // use ConditionalLink to display/function correctly on android
          <ConditionalLink
            condition={true}
            {...linkProps}
            onPress={(e: any) => {
              if (onPress) {
                // @ts-ignore
                onPress(e)
              }
            }}
          >
            {linkContents}
          </ConditionalLink>
        )}
      </>
    )
  }
}

// Get padding
// For some reason web is different
export function getBottomPadding(
  buttonSize: ButtonSize,
  isWeb: Boolean,
  isLoading: Boolean,
): PaddingValue {
  switch (buttonSize) {
    case 'xs':
      return isLoading ? '1' : '1'
    case 'sm':
      return isLoading ? '2' : '2'
    case 'md':
      return isLoading ? '3' : '2'
  }
}

export function getTopPadding(
  buttonSize: ButtonSize,
  isLoading: Boolean,
): PaddingValue {
  switch (buttonSize) {
    case 'xs':
      return isLoading ? '1' : '1'
    case 'sm':
      return isLoading ? '2' : '2'
    case 'md':
      return isLoading ? '3' : '3'
  }
}
const paddingXMap: Record<ButtonSize, number> = {
  xs: 2,
  sm: 3,
  md: 4,
}

// Added this to get around the fact that the padding on the loading pill button is slightly off for the small size
const paddingXMapLoading: Record<ButtonSize, number> = {
  xs: 2,
  sm: 2,
  md: 4,
}

function getPaddingX(
  buttonSize: ButtonSize,
  children: ReactNode,
  isLoading: boolean,
): number | string {
  if (children) {
    return isLoading ? paddingXMapLoading[buttonSize] : paddingXMap[buttonSize]
  } else if (isLoading && !children) {
    return '5px'
  } else {
    return '7px'
  }
}
