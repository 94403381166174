import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/core'
import { LoadingPage } from '../../../components/layout/loading-page'
import { AlertPopup } from '../dialog/alert-dialog'
import store from '../../../ducks/store'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import { idEquals } from '../../../ducks/helpers'
import { RootState, useSelector } from '../../../ducks/root-reducer'
import { Platform } from 'react-native'
import { Animated, StyleProp, TextInputProps } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useColorMode, useColorModeValue } from 'native-base'
import { Options } from 'react-native-image-crop-picker'
import { FormHeader } from './form-header'
import { FormField } from './form-field'
import { useTranslation } from 'react-i18next'
import { InfoText } from '../copy/text-info'
import { FormScrollWrapper } from './form-scroll-wrapper'
import {
  ADJUSTED_MARGIN_TOP,
  LINE_WIDTH,
  PADDING_HORIZONTAL,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import {
  FormFieldOption,
  FormFieldType,
  LanguageDirection,
  ScriptType,
  SubscriptionTierKey,
} from '../../../../../api/frontend-types'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  WHITE,
} from '../../../constants/ui-constants'
import { FormActionSheet } from '../action-sheet/form-action-sheet'
import { selectUser } from '../../../ducks/user/user'
import { isAtLeastTier } from '../../../modules/subscriptions/subscription-helpers'
import { RootStackNavigationProp } from '../../../navigation/types'
import { FastDream } from '../../../../../api/_openapi'
import useContentWidth from '../../../hooks/useContentWidth'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { ActionSheetMenuItem } from '../action-sheet/action-sheet-menu'
import { Row } from '../row/row'
import { Box, InterfaceBoxProps } from '../box/box'
import { View } from '../view/view'

const IMAGE_PADDING = 3

export type FormNavigationProp = RootStackNavigationProp<
  | 'PrivateDraftEdit'
  | 'PrivateDreamerEdit'
  | 'PrivateDreamAdd'
  | 'PrivateDreamEdit'
  | 'GroupAdd'
  | 'GroupProfileEdit'
  | 'PrivateDreamerProfileEdit'
  | 'PrivateDreamSettingsEdit'
  | 'PrivateNotificationsSettingsEdit'
  | 'PrivateDreamShare'
  | 'Signup'
>

export type FormFieldDetails = {
  name: string
  label?: string
  dir?: LanguageDirection
  scriptType?: ScriptType
  labelSelector?: (state: RootState) => string
  description?: string
  placeholder?: string
  placeholderSelector?: (state: RootState) => string
  type: FormFieldType
  options?: FormFieldOption[]
  // If the options are dynamic
  // we pass a function to get them from the store
  optionsSelector?: (state: RootState) => FormFieldOption[]
  maxOptions?: number
  containerProps?: InterfaceBoxProps
  containerStyleProps?: StyleProp<any>
  inputProps?: TextInputProps & { minHeight?: number; minRows?: number }
  rules?: any
  displayType?: 'input' | 'select' | 'textInput' | 'text'
  datePickerProps?: any
  imagePickerProps?: Options & { type: string; alt: string; sources?: string[] }
  checkboxProps?: {
    multiSelect: boolean
    disablePreSelected: boolean
  }
  checkboxWithFreeOptionProps?: {
    multiSelect: boolean
    disablePreSelected: boolean
    freeOptionTextMaxLength: number
  }
  loading?: boolean
  focus?: boolean
  autoFocus?: boolean // Is this the same as focus?
  searchable?: boolean
  hasLowerDivider?: boolean
  onChange?: (data: any) => void
  maintainSortOrder?: boolean
  hidden?: boolean
  forUsersOfAtLeastTier?: SubscriptionTierKey
  hasGradient?: boolean
}

export type FormScreen = {
  description?: string
  fields: FormFieldDetails[]
}

export type FormDetails = {
  pages: FormScreen[]
}

export type FormPageProps = {
  form: FormDetails
  name?: string
  onSubmit: (data: any) => void
  submitButtonLabel?: string
  nextButtonLabel?: string
  prepopulateValues?: any
  dream?: FastDream
  headerTitle: string
  showHeaderOptions?: boolean
  headerOptions?: ActionSheetMenuItem[]
  loading?: boolean
  loadingLabel?: string
  loadingIcon?: React.ReactNode
  confirmCancelMessage?: string
  checkOnCancelFields?: string[]
  loadingFormValues?: 'PROCESSING' | 'DONE'
  formActionSheet?: FormActionSheet
  pt?: number
  showScanIfFormIsNotValid?: boolean
  avatarFallbackName?: string
}

export function Form({
  form,
  name,
  onSubmit,
  nextButtonLabel = 'common.next',
  prepopulateValues,
  dream,
  headerTitle,
  headerOptions = [],
  showHeaderOptions = false,
  loading = false,
  loadingLabel = '',
  loadingIcon,
  confirmCancelMessage = '',
  checkOnCancelFields = [],
  loadingFormValues,
  formActionSheet,
  showScanIfFormIsNotValid = false,
  pt = 4,
  avatarFallbackName,
}: FormPageProps) {
  const navigation = useNavigation<FormNavigationProp>()
  const { t } = useTranslation()
  const { colorMode } = useColorMode()
  const statusBarStyle = useColorModeValue('dark', 'light')
  const statusBarColor = useColorModeValue(WHITE, BLACK)
  const color = useColorModeValue(BLACK, WHITE)
  const bgColor = useColorModeValue(CREAM, LIGHT_BLACK)

  const {
    handleSubmit,
    watch,
    control,
    formState: { isValid, errors },
    reset,
    setError,
    clearErrors,
    setFocus,
    trigger,
    getValues,
  } = useForm({
    defaultValues: prepopulateValues,
    mode: 'onChange',
  })

  const navigateToDreamScanFromDreamAdd = async () => {
    navigation.navigate('PrivateDreamScan', {
      onGoBack: (data) => {
        const { date, title, description, note } = data
        const uppercaseTitle = (title || '').toUpperCase()
        reset({
          date,
          title: uppercaseTitle,
          description,
          note,
        })
      },
    })
  }

  // STATE
  const [cancelMsgDialogOpen, setCancelMsgDialogOpen] = useState<boolean>(false)
  const [formIsValid, setFormIsValid] = React.useState(false)

  // HOOKS
  const { setColorMode } = useColorMode()
  const { contentWidth } = useContentWidth()

  // Page handling
  const [page, setPage] = React.useState(0)
  const pageDescription = useMemo(
    () => form.pages[page].description,
    [form, page],
  )
  const fields = useMemo(() => form.pages[page].fields, [form, page])
  const isLastPage = useMemo(() => page === form.pages.length - 1, [form, page])

  const isFirstPage = useMemo(() => page === 0, [page])

  const onNextPage = React.useCallback(() => {
    setPage(page + 1)
    trigger(form.pages[page + 1].fields.map((f) => f.name))
    scrollViewRef.current?.scrollToPosition(0, 0, true)
  }, [page])

  const onPreviousPage = React.useCallback(() => {
    setPage(page - 1)
    trigger(form.pages[page - 1].fields.map((f) => f.name))
  }, [page])

  const groupIdFromRoute = useActiveGroupId()

  // SELECTORS
  const privateGroupId: string | undefined = useSelector(selectPrivateGroupId)
  const user = useSelector(selectUser)

  // VARS
  const isWeb = Platform.OS === 'web'
  const activeGroupId = groupIdFromRoute || privateGroupId
  const userTier: SubscriptionTierKey =
    (user?.subscriptionTier as SubscriptionTierKey) || 'free'
  const hasContentWidth = Boolean(contentWidth)
  const isMobileLayout = hasContentWidth && contentWidth < WEB_MAX_WIDTH
  const defaultPaddingX = isWeb ? 0 : PADDING_HORIZONTAL
  const calculatedPaddingX = isMobileLayout ? PADDING_HORIZONTAL : 0
  const paddingX = hasContentWidth ? calculatedPaddingX : defaultPaddingX

  // MEMOS
  const isPrivateGroup = useMemo(() => {
    return idEquals(activeGroupId, privateGroupId)
  }, [activeGroupId, privateGroupId])

  const onCancelAndExitForm = React.useCallback(
    function onCancelAndExitForm() {
      // If user cancels out of user settings form
      if (name === 'userSettingsForm') {
        let val = store.getState().user?.user?.colorMode
        setColorMode(val)
      }
      if (name === 'signupForm' && isWeb) {
        reset && reset()
        return navigation.push('Home')
      }
      if (isWeb && !navigation?.canGoBack()) {
        window.history.back()
      } else {
        navigation.goBack()
      }
    },
    [name, navigation, isPrivateGroup, activeGroupId],
  )

  const scrollViewRef = React.useRef<KeyboardAwareScrollView>(null)

  const initialFocus = useMemo(
    () => form.pages[page].fields.find((f) => f.focus),
    [form, page],
  )

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('transitionEnd', (e: any) => {
      if (initialFocus) {
        setFocus(initialFocus.name)
      }
    })
    return unsubscribe
  }, [initialFocus, setFocus, page, navigation])

  React.useEffect(() => {
    setFormIsValid(isValid)
  }, [isValid])

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle,
      statusBarStyle,
      statusBarColor,
      header: () => {
        return (
          <FormHeader
            headerTitle={headerTitle}
            showHeaderOptions={showHeaderOptions}
            headerOptions={headerOptions}
            isLastPage={isLastPage}
            formIsValid={formIsValid}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            reset={reset}
            onNextPage={onNextPage}
            color={color}
            statusBarColor={statusBarColor}
            isFirstPage={isFirstPage}
            nextButtonLabel={nextButtonLabel}
            navigation={navigation}
            onPreviousPage={onPreviousPage}
            isDisabled={loading}
            setCancelMsgDialogOpen={setCancelMsgDialogOpen}
            onCancelAndExitForm={onCancelAndExitForm}
            confirmCancelMessage={confirmCancelMessage}
            getValues={getValues}
            checkOnCancelFields={checkOnCancelFields}
            formActionSheet={formActionSheet}
            showScanIfFormIsNotValid={showScanIfFormIsNotValid}
            navigateToDreamScanFromDreamAdd={navigateToDreamScanFromDreamAdd}
            defaultValues={prepopulateValues}
          />
        )
      },
    })
  }, [
    isFirstPage,
    isLastPage,
    formIsValid,
    colorMode,
    loading,
    page,
    headerTitle,
    user?.languageCode,
  ])

  React.useEffect(() => {
    if (loadingFormValues === 'DONE' && prepopulateValues) {
      reset && reset(prepopulateValues)
    }
  }, [loadingFormValues, prepopulateValues])

  return (
    <>
      <Animated.View
        style={[
          {
            flex: 1,
          },
          { opacity: loading ? 0.5 : 1 },
        ]}
        pointerEvents={loading ? 'none' : 'auto'}
      >
        <FormScrollWrapper
          scrollViewRef={scrollViewRef}
          name={name}
          contentContainerStyle={{
            maxWidth: WEB_MAX_WIDTH,
            width: '100%',
            marginHorizontal: 'auto',
            marginTop: ADJUSTED_MARGIN_TOP,
          }}
          // @ts-ignore
          dataSet={{
            scrollbarFormScrollWrapperAdjust: true,
          }}
        >
          <View pb={4} pt={pt}>
            {pageDescription && (
              <Row
                mb={3}
                mt={4}
                width={'100%'}
                p={4}
                borderColor={color}
                borderWidth={LINE_WIDTH}
                backgroundColor={bgColor}
              >
                <InfoText color={color}>{t(pageDescription)}</InfoText>
              </Row>
            )}
            {fields
              .filter((field) => {
                // Some fields may be restricted to certain subscription tiers
                const tierRestriction = field.forUsersOfAtLeastTier
                if (tierRestriction) {
                  return isAtLeastTier(userTier, tierRestriction)
                } else {
                  return true
                }
              })
              .map((field) => {
                const padding =
                  field.name === 'image' ? IMAGE_PADDING : paddingX

                return (
                  <Box key={field.name} px={padding}>
                    <FormField
                      formField={field}
                      control={control}
                      setFocus={setFocus}
                      setError={setError}
                      clearErrors={clearErrors}
                      dream={dream}
                      defaultValue={prepopulateValues[field.name]}
                      avatarFallbackName={avatarFallbackName}
                    />
                  </Box>
                )
              })}
          </View>
        </FormScrollWrapper>
      </Animated.View>
      <AlertPopup
        isOpen={cancelMsgDialogOpen}
        header={''}
        description={confirmCancelMessage || ''}
        onConfirm={onCancelAndExitForm}
        confirmLabel={t('common.yes')}
        cancelLabel={t('common.no')}
        onClose={() => {
          setCancelMsgDialogOpen(false)
        }}
      />
    </>
  )
}
