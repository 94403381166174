import { useColorModeValue } from 'native-base'
import React from 'react'
import { InterfaceButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SansText } from '../../common/copy/text-sans'
import { TextBadge } from '../../common/badge/text-badge'
import { PressableFixed } from '../../../utilities/PressableFixed'
import { LINE_WIDTH } from '../../../constants/constants'
import { Row } from '../../common/row/row'

type MenuLinkProps = InterfaceButtonProps & {
  children?: string
  badgeLabel?: string
  isSelected?: boolean
}

export const MenuLink = ({
  children,
  width,
  badgeLabel,
  onPress,
  isSelected,
  ...rest
}: MenuLinkProps) => {
  const backgroundColor = useColorModeValue(WHITE, BLACK)
  const color = useColorModeValue(BLACK, WHITE)
  return (
    <PressableFixed
      style={{
        backgroundColor,
        justifyContent: 'flex-start',
        paddingHorizontal: 4 * 4,
        paddingVertical: 3 * 4,
        width: width || '100%',
        borderRadius: 0,
        borderWidth: 0,
      }}
      onPress={onPress}
      {...rest}
    >
      <Row>
        <Row
          borderBottomColor={isSelected ? color : 'transparent'}
          borderBottomWidth={LINE_WIDTH}
        >
          <SansText>{children}</SansText>
        </Row>
        {badgeLabel && <TextBadge label={badgeLabel} marginX={2} />}
      </Row>
    </PressableFixed>
  )
}
