import React, { useState } from 'react'
import { FramedImage } from '../image/framed-image'
import { useSelector } from '../../../ducks/root-reducer'
import { selectDefaultArtStyle } from '../../../ducks/user/user'
import { useWatch } from 'react-hook-form'
import { updateCloudinaryUrlForDisplay } from '../../../modules/image-helpers/image-helpers'
import { selectSubscriptionStatus } from '../../../ducks/subscription/subscription'
import { refreshSubscriptionStatus } from '../../../ducks/subscription/thunks/subscription-thunks'
import { useDispatch } from 'react-redux'
import { IMG_RES } from '../../../constants/ui-constants'
import { regenerateImage } from '../../../ducks/dream-tag/functions/image-functions'
import {
  MediaObjectWithDream,
  ImageOnObject,
} from '../../../../../api/frontend-types'

type InputImageProps = {
  dreamId: string
  imageUrl: string | undefined
  onChangeImage: (image: MediaObjectWithDream) => void
  loading?: boolean
  control?: any
}

export const InputImage = ({
  dreamId,
  imageUrl,
  onChangeImage,
  loading,
  control,
}: InputImageProps) => {
  // STATE
  const [imageLoading, setImageLoading] = useState(loading)
  const [loadingLabel, setLoadingLabel] = useState('')

  // SELECTORS
  const defaultArtStyle = useSelector(selectDefaultArtStyle)
  const subscriptionStatus = useSelector(selectSubscriptionStatus)

  // HOOKS
  const dispatch = useDispatch<any>()
  const [title, description] = useWatch({
    control,
    name: ['title', 'description'],
  })

  React.useEffect(() => {
    dispatch(refreshSubscriptionStatus())
  }, [imageUrl])

  // VARS
  const resizedImgUrl = updateCloudinaryUrlForDisplay(imageUrl || '', IMG_RES)

  return (
    <FramedImage
      regenDisabled={!title && !description}
      alt={''}
      source={{ uri: resizedImgUrl }}
      loading={imageLoading}
      loadingLabel={loadingLabel}
      editable={true}
      setImageLoading={setImageLoading}
      setLoadingLabel={setLoadingLabel}
      onRegenerateImage={(
        userUploaded = false,
        image?: ImageOnObject | null,
        artStyle?: string,
        prompt?: string,
      ) => {
        setImageLoading(true)
        regenerateImage(
          { dreamId, title, description },
          onChangeImage,
          setImageLoading,
          userUploaded,
          image,
          artStyle || defaultArtStyle,
          subscriptionStatus,
          prompt,
        )
      }}
      imagesRemaining={subscriptionStatus.imagesRemaining}
      creditsRemaining={subscriptionStatus.creditsRemaining}
    />
  )
}
