import React, { useState } from 'react'
import { Check } from '../../../assets/react-native-svg/Check'
import { useTranslation } from 'react-i18next'
import { SansText } from '../copy/text-sans'
import { IconEllipsis } from '../../../assets/react-native-svg/icons/icons-ui'
import { ActionSheetMenu } from '../action-sheet/action-sheet-menu'
import { Box } from '../box/box'
import { Button } from '../nb-button/nb-button'
import { IconButton } from '../icon-button/icon-button'

export const HeaderRight = ({
  formIsValid,
  isLastPage,
  nextButtonLabel,
  handleSubmit,
  onSubmit,
  onNextPage,
  color,
  isDisabled,
  formActionSheet,
  formActionSheetHeading,
}: any) => {
  const [extraData, setExtraData] = useState({})
  const [actionsSheetOpen, setActionsSheetOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Box>
      {formIsValid && isLastPage && (
        <IconButton
          onPress={() => {
            handleSubmit((data: any) =>
              onSubmit({
                ...data,
                ...extraData,
              }),
            )()
            // setPage(0)
          }}
          mr={-3}
          variant="unstyled"
          _pressed={{
            opacity: 0.5,
          }}
          icon={<Check color={color} size={24} />}
          size={'sm'}
          isDisabled={isDisabled}
        />
      )}
      {formIsValid && isLastPage && formActionSheet && (
        <IconButton
          variant="unstyled"
          onPress={() => setActionsSheetOpen(true)}
          icon={<IconEllipsis color={color} size={'sm'} />}
        />
      )}
      {formIsValid && !isLastPage && (
        <Button
          variant="unstyled"
          onPress={onNextPage}
          mr={-3}
          _pressed={{
            opacity: 0.5,
          }}
          isDisabled={isDisabled}
        >
          <SansText fontSize={'sm'}> {t(nextButtonLabel)}</SansText>
        </Button>
      )}
      {formIsValid && isLastPage && formActionSheet && (
        <ActionSheetMenu
          menuItems={[]}
          isOpen={actionsSheetOpen}
          onClose={() => setActionsSheetOpen(false)}
          content={formActionSheet({
            data: extraData,
            setData: setExtraData,
            open: actionsSheetOpen,
            setOpen: setActionsSheetOpen,
          })}
          heading={formActionSheetHeading}
        />
      )}
    </Box>
  )
}
