import React, { useEffect, useMemo, useState } from 'react'
import { useColorModeValue, useColorMode } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import {
  BLACK,
  MOON_PHASE_MAP,
  PLACEHOLDER_COLOR_DARK,
  PLACEHOLDER_COLOR_LIGHT,
  WHITE,
} from '../../constants/ui-constants'
import { dateRangeFromKey } from './chart-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { ChartTitleText } from '../../components/composite/chart/chart-title'
import { FEW } from '../../i18n/config'
import { DateRangeWithKey, MoonPhase } from '../../../../api/frontend-types'
import { CountedFastTag, CountedFastTagService } from '../../../../api/_openapi'
import { formatDateForDatabase } from '../../modules/strings/string-helpers'
import { useActiveGroupId } from '../../hooks/useActiveGroupId'
import { Row } from '../../components/common/row/row'
import { Box } from '../../components/common/box/box'
import { IconMoonPhase } from '../../assets/react-native-svg/icons/icons-moon-phase'
import { SansText } from '../../components/common/copy/text-sans'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { useNavigation } from '@react-navigation/native'
import { PressableFixed } from '../../utilities/PressableFixed'
import { MainStackNavigationProp } from '../../navigation/types'
import { Column } from '../../components/common/column/column'
import { useSelector } from '../../ducks/root-reducer'
import {
  selectUserIsSupporter,
  selectUserIsPremium,
} from '../../ducks/user/user'
import { PremiumBadge } from '../../components/common/badge/premium-badge'
import { PleaseSubscribeDialog } from '../../components/composite/subscriptions/please-subscribe-dialog'
import { useHemisphere } from '../../hooks/useHemisphere'

type MoonPhasesChartProps = {
  dateRangeWithKey: DateRangeWithKey
  moonPhaseData?: CountedFastTag[]
  skipDataFetch?: boolean
}

const NUM_TAGS_TO_SHOW = 10

const MOON_PHASE_ORDER: MoonPhase[] = [
  'newMoon',
  'waxingCrescent',
  'firstQuarter',
  'waxingGibbous',
  'fullMoon',
  'waningGibbous',
  'lastQuarter',
  'waningCrescent',
]

export const MoonPhasesChart = ({
  dateRangeWithKey,
  moonPhaseData: initialMoonPhaseData,
  skipDataFetch,
}: MoonPhasesChartProps) => {
  const navigation = useNavigation<MainStackNavigationProp<any>>()
  const activeGroupId = useActiveGroupId()
  const [moonPhaseTags, setMoonPhaseTags] = useState<CountedFastTag[]>([])
  const [warningDialogIsOpen, setWarningDialogIsOpen] = useState<boolean>(false)

  // HOOKS
  const { t } = useTranslation()
  const { colorMode } = useColorMode()
  const textAndIconColor = useColorModeValue(BLACK, WHITE)
  const disabledColor = useColorModeValue(
    PLACEHOLDER_COLOR_LIGHT,
    PLACEHOLDER_COLOR_DARK,
  )
  const userIsPremium = useSelector(selectUserIsPremium)
  const userIsSupporter = useSelector(selectUserIsSupporter)
  const hemisphere = useHemisphere()

  const { width: windowWidth } = useWindowDimensions()
  const containerWidth = Math.min(windowWidth - 40, WEB_MAX_WIDTH - 40)

  const phaseRows = [MOON_PHASE_ORDER.slice(0, 4), MOON_PHASE_ORDER.slice(4)]
  const boxWidth = containerWidth / 4
  const iconSize = Math.min(boxWidth * 0.45, 20)

  // MEMOIZED VALUES
  const dateRange = useMemo(() => {
    return dateRangeFromKey(dateRangeWithKey.key)
  }, [dateRangeWithKey])

  useEffect(() => {
    if (skipDataFetch) {
      if (initialMoonPhaseData) {
        setMoonPhaseTags(initialMoonPhaseData)
      }
      return
    }

    if (activeGroupId) {
      const startDate = dateRange.start
        ? formatDateForDatabase(dateRange.start)
        : undefined
      const endDate = dateRange.end
        ? formatDateForDatabase(dateRange.end)
        : undefined

      CountedFastTagService.countedTypeTags(
        activeGroupId,
        'MOON_PHASE',
        NUM_TAGS_TO_SHOW,
        startDate,
        endDate,
      ).then((data: CountedFastTag[]) => {
        if (data.length > 0) {
          // Only update state if we have data
          setMoonPhaseTags(data)
        }
      })
    }
  }, [activeGroupId, dateRange, skipDataFetch, initialMoonPhaseData])

  const handlePhasePress = (id: string) => {
    navigation.push('MainTabs', {
      screen: 'MainStack',
      params: {
        screen: 'PrivateTagView',
        params: {
          id,
        },
      },
    })
  }

  const dataIsPresent = moonPhaseTags.length > 0

  const MoonPhaseRow = ({
    phases,
    isLastRow,
  }: {
    phases: MoonPhase[]
    isLastRow: boolean
  }) => (
    <Row
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'nowrap',
      }}
    >
      {phases.map((phase) => {
        const phaseTag = moonPhaseTags.find(
          (tag) => tag.name === `moonphase.${phase}`,
        )
        const datumIsZero = (phaseTag?.count ?? 0) === 0
        return (
          <Box
            key={phase}
            width={boxWidth}
            alignItems="center"
            justifyContent="center"
          >
            <PressableFixed
              onPress={
                userIsSupporter && phaseTag?.count
                  ? () => handlePhasePress(phaseTag?.id as string)
                  : !userIsSupporter
                  ? () => setWarningDialogIsOpen(true)
                  : undefined
              }
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                padding: 0,
              }}
              _hover={{
                opacity: 0.5,
              }}
            >
              {/* @ts-ignore */}
              {colorMode !== 'auto' && phase && (
                <IconMoonPhase
                  size={iconSize}
                  color={
                    datumIsZero || !userIsSupporter
                      ? disabledColor
                      : textAndIconColor
                  }
                  iconKey={phase}
                  hemisphere={hemisphere}
                />
              )}
              {userIsSupporter && dataIsPresent && (
                <SansText
                  mt={1}
                  size={12}
                  textAlign="center"
                  color={
                    datumIsZero || !userIsSupporter
                      ? disabledColor
                      : textAndIconColor
                  }
                >
                  {userIsSupporter ? phaseTag?.count || 0 : 0}
                </SansText>
              )}
              {!userIsSupporter && (
                <SansText
                  mt={1}
                  size={12}
                  textAlign="center"
                  color={disabledColor}
                >
                  ?
                </SansText>
              )}
            </PressableFixed>
          </Box>
        )
      })}
    </Row>
  )

  return (
    <>
      <PaddedContentArea>
        <Column>
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            mb={6}
            mt={2}
          >
            <Row alignItems="center" width="100%" justifyContent="center">
              <ChartTitleText
                width={undefined}
                mt={2}
                style={{
                  textAlign: 'center',
                }}
                type={!userIsSupporter ? 'gray' : undefined}
              >
                {t('tagType.moonPhase_plural', { count: FEW })}
              </ChartTitleText>
              {!userIsSupporter && <PremiumBadge ml={2} mt={1} />}
            </Row>
          </Row>

          <Box width="100%" alignItems="center">
            {phaseRows.map((rowPhases, index) => (
              <MoonPhaseRow
                key={index}
                phases={rowPhases}
                isLastRow={index === phaseRows.length - 1}
              />
            ))}
          </Box>

          {userIsSupporter && !dataIsPresent && (
            <SansText
              mt={1}
              size={12}
              textAlign="center"
              w="100%"
              alignItems="center"
              color={disabledColor}
              mb={!dataIsPresent ? 8 : 0}
            >
              {userIsSupporter && t('chartsPage.noDataInRangeMessage')}
            </SansText>
          )}
        </Column>
      </PaddedContentArea>
      {warningDialogIsOpen && (
        <PleaseSubscribeDialog
          isOpen={warningDialogIsOpen}
          includeCreditsLink={false}
          onClose={() => {
            setWarningDialogIsOpen(false)
          }}
          onConfirmAdditionalAction={() => {
            setWarningDialogIsOpen(false)
          }}
        />
      )}
    </>
  )
}
