import React, { useState } from 'react'
import { InterfaceImageProps } from 'native-base/lib/typescript/components/primitives/Image/types'
import { Loading } from '../../layout/loading'
import FastImage from 'react-native-fast-image'
import { BLACK, CREAM, WHITE } from '../../../constants/ui-constants'
import { FALLBACK_IMG } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'
import { useColorModeValue } from 'native-base'
import { StyleSheet, Platform, TouchableOpacity } from 'react-native'
import { sourceIsUriSource } from '../../../modules/image-helpers/image-helpers'
import { saveImageToCameraRoll } from './save-image'
import { SubscriptionStatusText } from '../../composite/subscriptions/subscription-status-text'
import { useNavigation } from '@react-navigation/native'
import { Box } from '../box/box'
import { NbImage } from './nb-image'
import {
  ActionSheetMenu,
  ActionSheetMenuItem,
} from '../action-sheet/action-sheet-menu'

// FALLBACK_IMG
// Probably would be better to use a dynamic thing here
// That can scale to different image sizes
// But since all our images are small and square, this works for now

// Simplified version of the InterfaceImageProps type
// We only support numbers for width and height, not '2xl' etc.
export type ImageProps = InterfaceImageProps & {
  width?: number
  height?: number
  loading?: boolean
  loadingLabel?: string
  imagesRemaining?: number | null
  creditsRemaining?: number | null
  tier?: string | null
  linkObject?: any
}

const FALLBACK_WIDTH = 512
const FALLBACK_HEIGHT = 512

const ImageWrapper = ({ children, onInteraction }: any) => {
  if (Platform.OS !== 'web') {
    return (
      <TouchableOpacity
        onLongPress={(e) => {
          e.stopPropagation?.()
          onInteraction()
        }}
      >
        {children}
      </TouchableOpacity>
    )
  }

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onInteraction()
      }}
    >
      {children}
    </div>
  )
}

// An image that will accept either a require()'d image or a URL
// And will display a fallback image if the image fails to load
// TODO: Add a loading state
export const Img = ({
  source,
  alt,
  width,
  height,
  loading,
  loadingLabel,
  mt = 4,
  mb = 4,
  imagesRemaining,
  creditsRemaining,
  tier,
  linkObject,
  ...rest
}: ImageProps) => {
  // STATE
  const [imageErr, setImageErr] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  // HOOKS
  const { t } = useTranslation()
  const navigation = useNavigation<any>()

  // PROPS
  const { style, ...otherProps } = rest

  // VARS
  const isStaticImage =
    typeof source === 'number' ||
    (Platform.OS === 'web' && typeof source === 'string')

  const w = width || FALLBACK_WIDTH
  const h = height || FALLBACK_HEIGHT

  const modalOptions: ActionSheetMenuItem[] = [
    {
      label: t('common.saveToCameraRoll'),
      closesMenu: true,
      onPress: () => {
        saveImageToCameraRoll(
          source,
          alt || '',
          t('common.saved'),
          t('common.error'),
          t('toast.errorAccessingCameraRoll.description'),
        )
      },
    },
    {
      label: t('common.cancel'),
      closesMenu: true,
      onPress: () => {
        setModalOpen(false)
      },
    },
  ]

  // FUNCTIONS
  const onImageError = () => {
    !imagesRemaining && setImageErr(true)
  }

  let srcKey = ''
  if (sourceIsUriSource(source)) {
    srcKey = source.uri || ''
  } else if (typeof source === 'number') {
    srcKey = source.toString()
  }

  const backgroundColor = useColorModeValue(BLACK, CREAM)
  const color = useColorModeValue(WHITE, BLACK)

  if (loading) {
    return (
      <Box
        height={h}
        width={w}
        backgroundColor={backgroundColor}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        mt={mt}
        mb={mb}
      >
        <Loading color={color} loadingLabel={loadingLabel} />
      </Box>
    )
  }

  if (imagesRemaining === 0 && !srcKey) {
    return (
      <Box
        height={h}
        width={w}
        backgroundColor={backgroundColor}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        mt={mt}
        mb={mb}
      >
        <SubscriptionStatusText
          textAlign={'center'}
          p={10}
          color={color}
          statusToShow="imagesRemaining"
          itemsRemaining={imagesRemaining}
          creditsRemaining={creditsRemaining}
          tier={tier || 'free'}
        />
      </Box>
    )
  }

  // For static images, we'll use the NativeBase Image component
  // For dynamic images, we'll use the FastImage component

  if (isStaticImage) {
    return (
      <NbImage
        alt={alt}
        key={`img-err-${imageErr}-${srcKey}`}
        // If there's an imageErr, we'll use the fallback image
        source={imageErr ? FALLBACK_IMG : source}
        width={w}
        height={h}
        style={{
          maxWidth: 300,
          maxHeight: 300,
          ...styles.image,
          aspectRatio: w / h,
          // @ts-ignore
          ...style,
        }}
        mt={mt}
        mb={mb}
        onError={() => {
          // NOTE, you might need to refresh the emulator
          // to see a real image load after an error
          console.log('Image failed to load')
          onImageError()
        }}
        {...otherProps}
      />
    )
  } else {
    return (
      <>
        <Box
          height={h}
          width={w}
          backgroundColor={backgroundColor}
          mt={mt}
          mb={mb}
        >
          <ImageWrapper onInteraction={() => setModalOpen(true)}>
            <FastImage
              key={`img-err-${imageErr}-${srcKey}`}
              source={{
                // @ts-ignore . RN Source prop is complex
                uri: source?.uri || '',
                priority: FastImage.priority.normal,
              }}
              style={{ width: w, height: w }}
              onError={() => {
                // NOTE, you might need to refresh the emulator
                // to see a real image load after an error
                console.log('Image failed to load')
                onImageError()
              }}
              resizeMode={FastImage.resizeMode.contain}
            />
          </ImageWrapper>
        </Box>
        {modalOpen && (
          <ActionSheetMenu
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            menuItems={modalOptions}
            heading={t('common.saveToCameraRoll')}
          />
        )}
      </>
    )
  }
}

const styles = StyleSheet.create({
  image: {
    resizeMode: 'contain',
  },
})
