import { selectShareGroupOptions } from '../ducks/groups/groups'
import { Platform } from 'react-native'
import { FastDream } from '../../../api/_openapi'
import { FormDetails, FormFieldDetails } from '../components/common/form/form'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types'
import {
  selectDreamAddPlaceholder,
  selectNotePlaceholder,
} from '../ducks/user/user'
import { Image } from '../../../api/frontend-types'
import { selectAllArtStyleOptions } from '../ducks/ui/ui'

// CONSTANTS
const DATE_BOX_PROPS: InterfaceBoxProps = {
  mb: 1,
  mt: 1,
}

const TITLE_BOX_PROPS: InterfaceBoxProps = {
  mx: 0,
  mb: 4,
  mt: 1,
}

const DESCRIPTION_BOX_PROPS: InterfaceBoxProps = {
  mx: 2,
  mt: 3,
}

const NOTE_BOX_PROPS: InterfaceBoxProps = {
  mx: 2,
  pt: 1,
}

const IMAGE_BOX_PROPS: InterfaceBoxProps = {
  mb: 5,
  mt: 0,
  pt: 0,
  ml: -3,
  mr: -3,
}

const DREAM_MAX_LEN = 10000
const TITLE_MAX_LEN = 60
const TEXTAREA_MIN_HEIGHT = 150
const NOTESAREA_MIN_HEIGHT = 75

type EditableDreamField = Pick<
  FastDream & {
    placeDisableAutoDetect?: string | null
    image: Image | null
    publicShareOptions: any
    artStyle: any
  },
  | 'image'
  | 'date'
  | 'displayDate'
  | 'title'
  | 'description'
  | 'sharedDescription'
  | 'note'
  | 'sharedNote'
  | 'tags'
  | 'isDraft'
  | 'place'
  | 'placeDisableAutoDetect'
  | 'publicShareOptions'
  | 'artStyle'
>

const dreamFields: Record<keyof EditableDreamField, FormFieldDetails> = {
  image: {
    name: 'image',
    type: 'image',
    containerProps: IMAGE_BOX_PROPS,
  },
  date: {
    name: 'date',
    type: 'date-with-moon-phase',
    displayType: Platform.OS === 'web' ? 'input' : 'text',
    containerProps: DATE_BOX_PROPS,
    datePickerProps: {
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ),
    },
  },
  displayDate: {
    name: 'displayDate',
    type: 'input',
    placeholder: 'addDreamPage.displayDate.placeholder',
    rules: {
      validate: async (value: string) => {
        if (value && value.length > 80) {
          return 'formWarning.tooLong'
        } else {
          return true
        }
      },
    },
  },
  title: {
    name: 'title',
    type: 'title',
    placeholder: 'addDreamPage.title.placeholder',
    containerProps: TITLE_BOX_PROPS,
    inputProps: {
      maxLength: TITLE_MAX_LEN,
    },
    autoFocus: false,
  },
  isDraft: {
    name: 'isDraft',
    description: `addDreamPage.draftToggleDescription`,
    type: 'toggle',
    label: 'common.draft',
  },
  publicShareOptions: {
    name: 'publicShareOptions',
    label: 'common.public',
    type: 'checkboxes-images',
    options: [
      {
        value: 'shareToMap',
        label: 'dreamShareMenu.shareToMap.title',
        languageKey: 'dreamShareMenu.shareToMap.title',
        image: undefined,
        subLabel: 'dreamShareMenu.shareToMap.description',
      },
    ],
    checkboxProps: {
      multiSelect: true,
      disablePreSelected: false,
    },
    hasLowerDivider: true,
  },
  description: {
    name: 'description',
    type:
      Platform.OS === 'web' ? 'textarea-controlled' : 'textarea-uncontrolled',
    placeholderSelector: selectDreamAddPlaceholder,
    inputProps: {
      maxLength: DREAM_MAX_LEN,
      minHeight: Platform.OS === 'web' ? undefined : TEXTAREA_MIN_HEIGHT,
    },
    containerProps: DESCRIPTION_BOX_PROPS,
    rules: {
      required: true,
      validate: (content = '') => {
        return content.trim().length !== 0
      },
    },
    hasLowerDivider: true,
  },
  note: {
    name: 'note',
    placeholderSelector: selectNotePlaceholder,
    type:
      Platform.OS === 'web' ? 'textarea-controlled' : 'textarea-uncontrolled',
    containerProps: NOTE_BOX_PROPS,
    inputProps: {
      maxLength: DREAM_MAX_LEN,
      minHeight: Platform.OS === 'web' ? undefined : NOTESAREA_MIN_HEIGHT,
      minRows: 5,
    },
  },
  artStyle: {
    name: 'artStyle',
    label: 'common.artStyle',
    description: 'addDreamPage.selectArtStyle',
    type: 'image-grid-select-artstyle',
    containerProps: {
      mb: 4,
    },
  },
  sharedDescription: {
    label: 'common.dream',
    name: 'sharedDescription',
    description: 'dreamShareMenu.editText.description',
    type:
      Platform.OS === 'web' ? 'textarea-controlled' : 'textarea-uncontrolled',
    placeholderSelector: selectDreamAddPlaceholder,
    inputProps: {
      maxLength: DREAM_MAX_LEN,
      minHeight: Platform.OS === 'web' ? undefined : TEXTAREA_MIN_HEIGHT,
    },
    containerProps: DESCRIPTION_BOX_PROPS,
    rules: {
      required: true,
      validate: (content = '') => {
        return content.trim().length !== 0
      },
    },
    hasLowerDivider: true,
  },
  sharedNote: {
    name: 'sharedNote',
    label: 'common.note',
    placeholderSelector: selectNotePlaceholder,
    type:
      Platform.OS === 'web' ? 'textarea-controlled' : 'textarea-uncontrolled',
    containerProps: NOTE_BOX_PROPS,
    inputProps: {
      maxLength: DREAM_MAX_LEN,
      minHeight: Platform.OS === 'web' ? undefined : NOTESAREA_MIN_HEIGHT,
      minRows: 5,
    },
  },
  tags: {
    name: 'tags',
    type: 'tagarea',
    containerProps: {
      mb: 4,
    },
  },
  place: {
    name: 'place',
    type: 'user-location',
    hidden: false,
  },
  placeDisableAutoDetect: {
    name: 'place',
    type: 'user-location-disable-autodetect',
    hidden: false,
  },
}

export const newDreamForm: FormDetails = {
  pages: [
    {
      fields: [
        {
          ...dreamFields.date,
          containerProps: { ...dreamFields.date.containerProps, mt: 3, mb: 3 },
        },
        // dreamFields.displayDate,
        dreamFields.title,
        dreamFields.description,
        dreamFields.note,
        dreamFields.place,
      ],
    },
    {
      fields: [dreamFields.artStyle, dreamFields.isDraft],
    },
  ],
}

export const editDraftForm: FormDetails = {
  pages: [
    {
      fields: [
        {
          ...dreamFields.date,
          containerProps: { ...dreamFields.date.containerProps, mt: 3, mb: 3 },
        },
        dreamFields.title,
        dreamFields.description,
        dreamFields.note,
        dreamFields.place,
        {
          ...dreamFields.isDraft,
          description: 'editDraftPage.editToggleDescription',
        },
      ],
    },
  ],
}

export const editDreamForm: FormDetails = {
  pages: [
    {
      fields: [
        dreamFields.image,
        dreamFields.title,
        dreamFields.date,
        // dreamFields.displayDate,
        dreamFields.description,
        dreamFields.note,
        dreamFields.placeDisableAutoDetect,
        dreamFields.tags,
      ],
    },
  ],
}

export const shareDreamForm: FormDetails = {
  pages: [
    {
      fields: [
        {
          label: 'shareDreamForm.groupsList.title',
          name: 'groups',
          type: 'checkboxes-images',
          optionsSelector: selectShareGroupOptions,
          maxOptions: 3,
          checkboxProps: {
            multiSelect: true,
            disablePreSelected: false,
          },
          hasLowerDivider: true,
        },
        dreamFields.publicShareOptions,
        dreamFields.sharedDescription,
        dreamFields.sharedNote,
      ],
    },
  ],
}
