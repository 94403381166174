import React from 'react'
import { FastTag } from '../../../../api/_openapi'
import { SymbolComponent, SymbolComponentMap } from '../../assets/symbols'
import { snakeCase } from '../../modules/strings/string-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import {
  BLACK,
  CREAM,
  LIGHT_BLACK,
  MOON_PHASE_MAP,
  WHITE,
} from '../../constants/ui-constants'
import { useColorModeValue, useColorMode } from 'native-base'
import { Platform } from 'react-native'
import { AvatarCircle } from '../../components/common/avatar/avatar-circle'
import { Center } from '../../components/common/center/center'
import { IconMoonPhase } from '../../assets/react-native-svg/icons/icons-moon-phase'
import { MoonPhase } from '../../../../api/frontend-types'
import { useHemisphere } from '../../hooks/useHemisphere'
import { MoonIcon } from '../../assets/react-native-svg/illustrations/Moon'
import { Box } from '../../components/common/box/box'

type TagViewImageProps = {
  tag: FastTag | null
  pageWidth: number
}

export default function TagViewImage({ tag, pageWidth }: TagViewImageProps) {
  const color = useColorModeValue(BLACK, WHITE)
  const forceColorModeValue = useColorModeValue('light', 'dark')
  const imageBgColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const hemisphere = useHemisphere()

  const isWeb = Platform.OS === 'web'
  const isSymbolType = tag?.type === 'SYMBOL'
  const isMoonPhaseTag = tag?.type === 'MOON_PHASE'

  if (!tag) {
    return null
  }

  if (
    (isSymbolType && SymbolComponentMap[snakeCase(tag?.name)]) ||
    isMoonPhaseTag
  ) {
    const SymbolComponent: SymbolComponent =
      SymbolComponentMap[snakeCase(tag?.name)]

    if (isMoonPhaseTag) {
      return (
        <Center
          backgroundColor={imageBgColor}
          width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
          pointerEvents="none"
        >
          <IconMoonPhase
            size={200}
            iconKey={tag.name.replace('moonphase.', '') as MoonPhase}
            color={color}
            hemisphere={hemisphere}
          />
        </Center>
      )
    }
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <SymbolComponent color={color} size={200} />
      </Center>
    )
  } else if (tag.imageUrl) {
    return (
      <Center
        backgroundColor={imageBgColor}
        width={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        height={isWeb ? Math.min(pageWidth, WEB_MAX_WIDTH / 2) : pageWidth}
        pointerEvents="none"
      >
        <AvatarCircle
          source={{ uri: tag.imageUrl }}
          name={'dunno'}
          loading={false}
          disablePress={true}
          size={'2xl'}
        />
      </Center>
    )
  } else {
    return null
  }
}
