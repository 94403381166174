import { DreamImage_read, DreamImageService } from '../../../../../api/_openapi'
import { undecorateId } from '../../../../../api/decorate-ids'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { showImageToast } from '../../../modules/image-helpers/image-helpers'
import {
  ImageOnObject,
  MediaObjectWithDream,
  SubscriptionStatus,
} from '../../../../../api/frontend-types'

export function regenerateImage(
  dream: { dreamId: string; title: string; description: string },
  onChangeImage: (image: MediaObjectWithDream) => void,
  setImageLoading: (loading: boolean) => void,
  userUploaded = false,
  userUploadedImage: ImageOnObject | null = null,
  artStyle: string,
  subscriptionStatus: SubscriptionStatus,
  prompt?: string,
) {
  if (userUploaded && userUploadedImage) {
    onChangeImage({
      id: userUploadedImage.id,
      url: userUploadedImage.url,
    })
    setImageLoading(false)
    return
  } else if (userUploaded && !userUploadedImage) {
    onChangeImage({
      id: '',
      url: '',
    })
    setImageLoading(false)
    return
  }

  // Generate the image
  DreamImageService.dreamImage({
    style: undecorateId(artStyle),
    dream: dream.dreamId,
    dreamTitle: dream.title || undefined,
    dreamText: dream.description || undefined,
    // @ts-ignore ~ this should be in there just not documented
    prompt: prompt,
  })
    .then((resImage: DreamImage_read) => {
      // Change the image to a DreamImage
      const img = {
        // @ts-ignore
        id: resImage.id || '', // image field is the ID
        // @ts-ignore
        url: resImage.url,
      }
      showImageToast(subscriptionStatus)
      onChangeImage(img)
      setImageLoading(false)
    })
    .catch((err) => {
      setImageLoading(false)
      console.log('image error', err.body)

      if (err.status === 403) {
        elsewhereToast.showSubscriptionStatusToast({
          status: 'error',
          duration: 10000,
          subscriptionStatus,
        })
      } else if (err.body === 'usage_limit_reached') {
        elsewhereToast.showSubscriptionStatusToast({
          status: 'error',
          duration: 10000,
          subscriptionStatus,
        })
      } else if (err.body === 'censored') {
        elsewhereToast.showToast({
          title: 'toast.errorGeneratingImage.title',
          description: 'toast.errorGeneratingImage.description',
          status: 'error',
          duration: 5000,
        })
      } else {
        elsewhereToast.showToast({
          title: 'Failed to generate image',
          description: 'Failed to generate image. Please try again.',
          status: 'error',
          duration: 5000,
        })
      }
    })
}
