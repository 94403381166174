import React, { useState, useCallback } from 'react'
import { G, Rect, Svg, Text, Circle, Path } from 'react-native-svg'
import { scaleLinear } from 'd3-scale'
import {
  LINE_WIDTH,
  MEDIUM_FONT_SIZE,
  SANS_LIGHT_FONT_WEIGHT,
  SMALL_FONT_SIZE,
  WEB_MAX_WIDTH,
} from '../../../constants/constants'
import { Platform } from 'react-native'
import { useColorModeValue } from 'native-base'
import {
  BLACK,
  CREAM,
  WHITE,
  LIGHT_BLACK,
} from '../../../constants/ui-constants'
import { PressableFixed } from '../../../utilities/PressableFixed'
import i18n from '../../../i18n/i18nnext'
import { FastTag } from '../../../../../api/_openapi'
import {
  getLanguageForTag,
  getSansFont,
} from '../../../modules/language-helpers/language-helpers'
import { useNavigation } from '@react-navigation/native'
export type HorizontalBarChartDatum = {
  x: string
  y: number | undefined
  tag: FastTag
  iconPath?: string
}

type HorizontalBarChartProps = {
  data: HorizontalBarChartDatum[]
  width?: number
  maxWidth?: number
  barHeight?: number // The height of the chart if all bars have data
}

export const HorizontalBarChart = ({
  data,
  width = 300,
  barHeight = 10,
  maxWidth = WEB_MAX_WIDTH,
}: HorizontalBarChartProps) => {
  // STATE
  const [hoveredBar, setHoveredBar] = useState<number | null>(null)
  const [selectedBar, setSelectedBar] = useState<
    HorizontalBarChartDatum | undefined
  >(undefined)

  const navigation = useNavigation<any>()

  // Add debounced navigation handler
  const [isNavigating, setIsNavigating] = useState(false)

  const navigateToTag = useCallback(
    (tagId: string) => {
      if (isNavigating) return
      setIsNavigating(true)

      navigation.push('MainTabs', {
        screen: 'MainStack',
        params: {
          screen: 'PrivateTagView',
          params: {
            id: tagId,
          },
        },
      })

      // Reset after a short delay
      setTimeout(() => {
        setIsNavigating(false)
      }, 1000)
    },
    [navigation, isNavigating],
  )

  // VARS
  const TOOLTIP_BAR_GAP = 4 // Gap between tooltip and bar
  const EDGE_OFFSET = 2
  const chartFontSize = MEDIUM_FONT_SIZE
  const tooltipFontSize = SMALL_FONT_SIZE
  const chartFontWeight = SANS_LIGHT_FONT_WEIGHT
  const tooltipDiameter = barHeight * 2

  const chartWidth = Math.min(width, maxWidth - tooltipDiameter)
  const numBars = data.length
  const effectiveWidth = chartWidth - EDGE_OFFSET * 2
  const maxValue = Math.max(...data.map((bar) => bar.y || 0))

  // I18N
  const userLanguage = i18n.resolvedLanguage || 'en'
  const dir = i18n.dir(userLanguage)

  const textMargin = Platform.select({
    web: 10,
    default: 8, // Mobile values
  })
  const barMargin = Platform.select({
    web: 2,
    default: 0, // Mobile values
  })
  const labelHeight = Math.floor(barHeight * 1.5)
  const labelBarHeight = labelHeight + barHeight + textMargin + barMargin
  const chartHeight = labelBarHeight * numBars + EDGE_OFFSET * 2 + barHeight

  // SCALES
  const barScale = scaleLinear<number>()
    .domain([0, maxValue])
    .range([
      0,
      effectiveWidth - tooltipDiameter - TOOLTIP_BAR_GAP - EDGE_OFFSET,
    ])

  // HANDLERS
  const onMouseOver = (x: number) => {
    setHoveredBar(x)
  }

  const onMouseOut = () => {
    setHoveredBar(null)
  }

  const color = useColorModeValue(BLACK, WHITE)
  const fill = useColorModeValue(CREAM, LIGHT_BLACK)

  return (
    <Svg width={chartWidth} height={chartHeight + 10}>
      <G>
        {data.map((bar, index) => {
          const barW = barScale(bar.y || 0)
          const barNumber = index + 1

          const labelY =
            barNumber * labelBarHeight - labelHeight + chartFontSize / 2
          const barY = labelY + textMargin // Add margin below text

          // I18N
          // For RTL, the bar should be at the right edge
          const xOffset = 2 // For stroke width
          const barX = dir === 'rtl' ? chartWidth - barW - xOffset : xOffset
          const textX = dir === 'rtl' ? chartWidth - xOffset : xOffset
          const barEnd = dir === 'rtl' ? chartWidth - barW : barW
          const barTipGap = dir === 'rtl' ? -TOOLTIP_BAR_GAP : TOOLTIP_BAR_GAP
          const toolTipWOffset =
            dir === 'rtl' ? -(tooltipDiameter / 2) : tooltipDiameter / 2
          const edgeOffset = dir === 'rtl' ? -EDGE_OFFSET : EDGE_OFFSET
          const circleX = barEnd + barTipGap + toolTipWOffset + edgeOffset
          const textAnchor = dir === 'rtl' ? 'end' : 'start'
          const tagLanguage = getLanguageForTag(bar.tag, userLanguage)
          const barFont = getSansFont(tagLanguage)

          return (
            <G
              key={`${bar.x}${index}`}
              onMouseOver={() => onMouseOver(index)}
              onMouseOut={onMouseOut}
              {...Platform.select({
                native: {
                  onPress: () => navigateToTag(bar.tag.id as string),
                },
                web: {
                  onPress: () => navigateToTag(bar.tag.id as string),
                  style: {
                    cursor: 'pointer',
                    outline: 'none',
                  },
                },
              })}
            >
              <Rect
                x={barX}
                y={barY}
                width={barW}
                height={barHeight}
                fill={fill}
                stroke={color}
                strokeWidth={LINE_WIDTH}
              />
              <Rect
                fill="transparent"
                width={barW}
                height={chartFontSize + 4}
                x={0}
                y={labelY - chartFontSize}
              />
              {bar.iconPath && (
                <G x={textX - 4} y={labelY - chartFontSize + 1}>
                  <G
                    width={chartFontSize}
                    height={chartFontSize}
                    viewBox="0 0 30 30"
                    scale={chartFontSize / 25} // Scale to match font size
                  >
                    <Path fill={'currentColor'} d={bar.iconPath} />
                  </G>
                </G>
              )}
              <Text
                x={
                  bar.iconPath
                    ? textX +
                      chartFontSize +
                      Platform.select({
                        web: 8,
                        default: 2, // Mobile value
                      })
                    : textX
                }
                y={labelY}
                fontSize={chartFontSize}
                fontWeight={chartFontWeight}
                fill={color}
                fontFamily={barFont}
                textAnchor={textAnchor}
              >
                {bar.x}
              </Text>
              {/* Always show tooltip on mobile, show on hover for web */}
              {Platform.OS === 'web' && hoveredBar === index ? (
                <G>
                  <Circle
                    cx={circleX}
                    cy={barY + barHeight / 2}
                    r={tooltipDiameter / 2}
                    fill={fill}
                    stroke={color}
                    strokeWidth={LINE_WIDTH}
                  />
                  <Text
                    x={circleX}
                    y={barY + barHeight / 2 + tooltipFontSize / 3}
                    textAnchor="middle"
                    fontSize={chartFontSize}
                    fontWeight={chartFontWeight}
                    fill={color}
                    fontFamily={'ABCFavorit-Book'}
                  >
                    {bar.y}
                  </Text>
                </G>
              ) : Platform.OS !== 'web' ? (
                <Text
                  x={circleX}
                  y={barY + barHeight / 2 + tooltipFontSize / 3}
                  textAnchor="middle"
                  fontSize={chartFontSize}
                  fontWeight={chartFontWeight}
                  fill={color}
                  fontFamily={'ABCFavorit-Book'}
                >
                  {bar.y}
                </Text>
              ) : null}
            </G>
          )
        })}
      </G>
    </Svg>
  )
}
