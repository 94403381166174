import React, { useState } from 'react'
import { Box } from '../../box/box'
import { useTranslation } from 'react-i18next'
import { SansText } from '../../copy/text-sans'
import { Dimensions, TouchableOpacity } from 'react-native'
import { Center } from '../../center/center'
import { Column } from '../../column/column'
import { GridDisplay } from '../../../layout/grid-display'
import { NbImage } from '../../image/nb-image'
import { ElsewhereIcon } from '../../elsewhere-icon/elsewhere-icon'
import useContentWidth from '../../../../hooks/useContentWidth'
import { AlertPopup } from '../../dialog/alert-dialog'
import { useColorModeValue } from 'native-base'
import i18n from 'i18next'
import {
  LINE_WIDTH,
  MEDIUM_FONT_SIZE,
  PADDING_HORIZONTAL_PIXELS,
} from '../../../../constants/constants'
import { FormFieldOption } from '../../../../../../api/frontend-types'
import {
  BLACK,
  CREAM,
  DARKER_CREAM,
  LIGHT_BLACK,
  LIGHT_MID_GRAY,
  WHITE,
  LIGHTEST_BLACK,
} from '../../../../constants/ui-constants'
import { PremiumBadge } from '../../badge/premium-badge'

type ImageGridSelectProps = {
  options: FormFieldOption[]
  onChange: (value: FormFieldOption) => void
  value: string | undefined
  premiumAlertTitle?: string
  premiumAlertMsg?: string
  fontSize?: number
}

const SQUARE_PADDING = 2
const NUM_COLS = 3

export const ImageGridSelect = ({
  options,
  onChange,
  value,
  premiumAlertTitle,
  premiumAlertMsg,
  fontSize,
}: ImageGridSelectProps) => {
  // STATE
  const [premiumWarningDialogOpen, setPremiumWarningDialogOpen] =
    useState(false)

  // HOOKS
  const { t } = useTranslation()
  const boxColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const highlightedBoxColor = useColorModeValue(DARKER_CREAM, LIGHTEST_BLACK)
  const { paddedContentWidth } = useContentWidth()
  const textColor = useColorModeValue(BLACK, WHITE)
  const checkboxColor = useColorModeValue(BLACK, WHITE)
  const checkboxIconColor = useColorModeValue(WHITE, BLACK)
  const lockedTextColor = useColorModeValue(LIGHT_MID_GRAY, LIGHT_MID_GRAY)

  // VARS
  const numPaddings = NUM_COLS * 2 // Each square has padding left and right
  const gridPadding = LINE_WIDTH * 6 + SQUARE_PADDING * numPaddings * 4 // 3 lines of padding
  const imgWidth = (paddedContentWidth - gridPadding) / 3
  const defaultAlertMsg = t('common.premiumFeature')
  const alertMsg = premiumAlertMsg || defaultAlertMsg
  const captionFontSize = fontSize || MEDIUM_FONT_SIZE
  const isRtl = i18n.dir() === 'rtl'

  // GRID ITEMS
  const gridItems = options.map((option: FormFieldOption, i) => {
    const { isPremium, locked, label, imageObject, value: optionValue } = option
    const translatedLabel = t(label)
    const isSelected = value === optionValue

    return {
      id: optionValue,
      item: (
        <Column
          alignItems={'center'}
          padding={SQUARE_PADDING}
          paddingBottom={3}
          width={'100%'}
          height={'auto'}
          backgroundColor={isSelected ? highlightedBoxColor : boxColor}
          flexGrow={1}
        >
          <TouchableOpacity
            key={optionValue}
            onPress={() => {
              if (locked) {
                setPremiumWarningDialogOpen(true)
              } else {
                onChange(option)
              }
            }}
          >
            {/* IMAGE WRAPPER */}
            <Box
              style={{ position: 'relative' }}
              flexDirection={'row'}
              justifyContent={'center'}
            >
              <NbImage
                source={imageObject}
                width={imgWidth}
                height={imgWidth}
                opacity={locked ? 0.5 : 1}
                alt={translatedLabel}
              />
              {isSelected && (
                <Box
                  position={'absolute'}
                  right={isRtl ? undefined : 1}
                  left={isRtl ? 1 : undefined}
                  top={1}
                  backgroundColor={checkboxColor}
                  padding={1}
                  borderColor={checkboxIconColor}
                  borderWidth={1}
                >
                  <ElsewhereIcon
                    iconKey={'check'}
                    size={'2xs'}
                    color={checkboxIconColor}
                  />
                </Box>
              )}
              {isPremium && (
                <PremiumBadge
                  position={'absolute'}
                  right={isRtl ? undefined : 1}
                  left={isRtl ? 1 : undefined}
                  bottom={1}
                />
              )}
            </Box>
            <SansText
              fontSize={captionFontSize}
              mt={4}
              textAlign={'center'}
              color={locked ? lockedTextColor : textColor}
            >
              {translatedLabel}
            </SansText>
          </TouchableOpacity>
        </Column>
      ),
    }
  })

  return (
    <>
      <Center width={'100%'}>
        <GridDisplay
          width={Dimensions.get('window').width - PADDING_HORIZONTAL_PIXELS * 2}
          items={gridItems}
          numCols={NUM_COLS}
        />
      </Center>
      <AlertPopup
        isOpen={premiumWarningDialogOpen}
        header={premiumAlertTitle && premiumAlertTitle}
        description={alertMsg}
        onClose={() => {
          setPremiumWarningDialogOpen(false)
        }}
        onConfirm={() => {
          setPremiumWarningDialogOpen(false)
        }}
        hideCancel={true}
        confirmLabel={t('common.ok')}
      />
    </>
  )
}
