import React, { useState, useEffect } from 'react'
import { Form } from '../../components/common/form/form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { shareDreamForm } from '../../forms/dream-form'
import {
  FastDream,
  FastShare,
  FastShareService,
  PublishRequest,
  PublishRequestService,
} from '../../../../api/_openapi'
import { formatDateTimeForDatabase } from '../../modules/strings/string-helpers'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { useSelector } from '../../ducks/root-reducer'
import { RootStackScreenProps } from '../../navigation/types'
import { useDream } from '../../hooks/useDream'
import { isDreamOnPublicMap } from '../../ducks/collections/functions/collections-functions'
import { selectIsElsewhereTeam } from '../../ducks/user/user'
import { DREAM_MAPPING_COLLECTION } from '../../constants/constants'
import { elsewhereToast } from '../../components/common/toast/toast'
import { addFastDream } from '../../ducks/dream-tag/dream-tag'

export default function DreamShare({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDreamShare'>) {
  // State
  const [shares, setShares] = useState<FastShare[]>([])
  const [dreamSharedToMap, setDreamSharedToMap] = useState<boolean>(false)
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)

  // Selectors
  const privateGroupId = useSelector(selectPrivateGroupId)

  // Hooks
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()

  // Vars
  const id = (route?.params as any).id as string
  const dreamToShare = useDream(id)

  const fetchShares = async () => {
    if (!id) {
      console.log('ID not found in fetchShares')
      return []
    }
    return FastShareService.getDreamShares(id)
  }

  const setAndFetchShares = async () => {
    setShares(await fetchShares())
  }

  // Get the groups the dream has already been shared to
  useEffect(() => {
    setAndFetchShares()
  }, [id])

  // EFFECTS
  // useEffect(() => {
  //   if (id) {
  //     isDreamOnPublicMap(id).then((result) => {
  //       setDreamSharedToMap(result)
  //       console.log('dream is on public map', result)
  //     })
  //   }
  // }, [id])

  const onSubmitForm = async (data: {
    groups: string[]
    sharedDescription: string
    sharedNote?: string
    publicShareOptions?: string[]
  }) => {
    if (!dreamToShare.dream || !privateGroupId) {
      return
    }

    const publishRequest: PublishRequest = {}

    if (data.sharedDescription !== dreamToShare.dream.description) {
      publishRequest.sharedDescription = data.sharedDescription
    }

    if (data.sharedNote !== dreamToShare.dream.note) {
      publishRequest.sharedNote = data.sharedNote || ''
    }

    const sharedToMap = await isDreamOnPublicMap(id)

    if (
      data.publicShareOptions &&
      data.publicShareOptions.includes('shareToMap') &&
      !sharedToMap
    ) {
      publishRequest.publishCollectionIds = [DREAM_MAPPING_COLLECTION]
    } else if (
      (!data.publicShareOptions ||
        !data.publicShareOptions.includes('shareToMap')) &&
      sharedToMap
    ) {
      publishRequest.unpublishCollectionIds = [DREAM_MAPPING_COLLECTION]
    }

    //refetch to ensure they're up-to-date
    const shares = await fetchShares()

    //Handle new shares (excluding private group and groups already shared to)
    const shareGroups = data.groups.filter(
      (groupId) =>
        groupId !== privateGroupId &&
        !shares.find((s) => s.groupId === groupId),
    )

    if (shareGroups.length > 0) {
      publishRequest.publishGroupIds = shareGroups
    }

    //Handle unshares
    const unshareGroupIds = shares
      .filter((share) => {
        return !data.groups.includes(share.groupId)
      })
      .map((share) => share.groupId)

    if (unshareGroupIds.length > 0) {
      publishRequest.unpublishGroupIds = unshareGroupIds
    }

    console.log('publish request', publishRequest)
    PublishRequestService.publishEntry(id, publishRequest)
      .then(() => {
        dispatch(
          addFastDream({
            ...(dreamToShare.dream as FastDream),
            sharedDescription: publishRequest.sharedDescription || null,
            sharedNote: publishRequest.sharedNote || null,
            updatedAt: formatDateTimeForDatabase(new Date()),
          }),
        )

        elsewhereToast.showToast({
          description: 'toast.dreamsShared.description',
          status: 'success',
        })
      })
      .catch((err: any) => {
        console.log('Error sharing dream', err)
        elsewhereToast.showToast({
          description: 'Error sharing dream',
          status: 'error',
        })
      })

    navigation.goBack()
  }

  const sharedDescription =
    dreamToShare?.dream?.sharedDescription ||
    dreamToShare?.dream?.description ||
    ''
  const sharedNote =
    dreamToShare?.dream?.sharedNote || dreamToShare?.dream?.note || ''

  const shareForm: any = { ...shareDreamForm }
  shareForm.pages[0].fields[1].hidden = !isElsewhereTeam

  return (
    <Form
      name={'shareDreamForm'}
      form={shareForm}
      onSubmit={onSubmitForm}
      prepopulateValues={{
        sharedDescription,
        sharedNote,
        groups: shares.map((s) => s.groupId),
        publicShareOptions: dreamSharedToMap ? ['shareToMap'] : [],
      }}
      headerTitle={t('common.share')}
      loading={!dreamToShare}
      loadingFormValues={!dreamToShare ? 'PROCESSING' : 'DONE'}
      loadingLabel={t('loading.loadingGeneric')}
    />
  )
}
