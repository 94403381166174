import React, { useState } from 'react'
import { FastTagDescription } from '../../../../api/_openapi'
import { useFocusEffect, RouteProp } from '@react-navigation/native'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import {
  fetchSymbolDescriptions,
  fetchTagById,
} from '../../ducks/dream-tag/functions/tag-functions'
import { selectSubscriptionStatus } from '../../ducks/subscription/subscription'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
  MainStackParamList,
} from '../../navigation/types'
import { FastTag } from '../../../../api/_openapi'
import { useActiveGroupId } from '../../hooks/useActiveGroupId'
import TagViewComponent from './tag-view-component'
import { cap } from '../../modules/strings/string-helpers'
import { useTranslation } from 'react-i18next'
import { selectGroupById } from '../../ducks/groups/groups'
import { selectFriendByTagId } from '../../ducks/friends/friends'
import { TagType } from '../../../../api/frontend-types'

export type TagViewNavigationProp = MainStackNavigationProp<'PrivateTagView'>
export type TagViewRouteProp = RouteProp<MainStackParamList, 'PrivateTagView'>

export default function TagView({
  navigation,
  route,
}: MainStackScreenProps<'PrivateTagView'>) {
  // ROUTE PARAMS
  const { id } = route.params
  const { t } = useTranslation()
  const [tag, setTag] = useState<
    (Omit<FastTag, 'type'> & { type: TagType | 'MOON_PHASE' }) | null
  >(null)
  const friend = useSelector(selectFriendByTagId(tag?.id))

  // STATE
  const [symbolDictItems, setSymbolDictItems] = useState<FastTagDescription[]>(
    [],
  )
  // SELECTORS
  const activeGroupId = useActiveGroupId()
  const currentGroup = useSelector(selectGroupById(activeGroupId))
  const subscriptionStatus = useSelector(selectSubscriptionStatus)
  const creditsRemaining = subscriptionStatus?.creditsRemaining || 0
  const appUser = useSelector(selectUser)
  const userCanSeeMap = Boolean(appUser?.subscriptionTier === 'supporter')

  // EFFECTS
  useFocusEffect(
    React.useCallback(() => {
      fetchTagById(id)
        .then((fastTag) => {
          setTag(fastTag)
          const isSymbolTag = fastTag?.type === 'SYMBOL'
          const isThemeTag = fastTag?.type === 'THEME'
          if (fastTag && appUser && (isSymbolTag || isThemeTag)) {
            fetchSymbolDescriptions(id)
              .then((symbolInterpretations) => {
                setSymbolDictItems(symbolInterpretations)
              })
              .catch((e) => {
                console.log(e)
              })
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }, [id, subscriptionStatus, creditsRemaining]),
  )

  const isSymbolTag = tag?.type === 'SYMBOL'
  const isThemeTag = tag?.type === 'THEME'
  const isMoonPhaseTag = tag?.type === 'MOON_PHASE'
  const headerTitle = isSymbolTag
    ? t('tagType.symbol_plural', { count: 1 })
    : isThemeTag
    ? t('tagType.theme_plural', { count: 1 })
    : isMoonPhaseTag
    ? t('tagType.moonPhase_plural', { count: 1 })
    : t('common.tag_plural', { count: 1 })

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: cap(headerTitle),
    })
  }, [navigation, tag, headerTitle])

  return (
    <TagViewComponent
      tag={
        tag
          ? {
              ...tag,
              imageUrl: friend?.imageUrl || tag?.imageUrl || null,
            }
          : null
      }
      symbolDictItems={symbolDictItems}
      showMap={userCanSeeMap}
      navigation={navigation}
      route={route}
      currentGroup={currentGroup}
    />
  )
}
