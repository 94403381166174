import React from 'react'
import { Button, useColorModeValue } from 'native-base'
import { TextInput } from './text-input'
import { IconCaretDown } from '../../../assets/react-native-svg/icons/icons'
import { RefCallBack } from 'react-hook-form'
import {
  BLACK,
  PLACEHOLDER_COLOR_LIGHT,
  PLACEHOLDER_COLOR_DARK,
  LIGHT_MID_GRAY,
  WHITE,
} from '../../../constants/ui-constants'
import { Platform } from 'react-native'
import { SansText } from '../copy/text-sans'
import { LINE_WIDTH } from '../../../constants/constants'
import { DatePickerNavigationProp } from './date-picker/date-picker'
import { SelectWithActionSheetNavigationProp } from './select/select-with-action-sheet'
import { ScriptType } from '../../../../../api/frontend-types'
import { Box } from '../box/box'
import { Row } from '../row/row'

export type InputFieldFakeNavigationProp =
  | DatePickerNavigationProp
  | SelectWithActionSheetNavigationProp

type FakeInputType = 'input' | 'select' | 'textInput' | 'text'

type InputFieldFakeProps = {
  onPress: () => void
  placeholder: string
  mt?: number
  mb?: number
  value?: string
  focus?: boolean
  type?: FakeInputType
  variant?: 'underlined' | 'unstyled'
  textAlign?: 'left' | 'center' | 'right'
  isInvalid?: boolean
  setFocus?: any
  fieldName?: string
  width?: number | string
  inputRef?: RefCallBack
  isReadOnly?: boolean
  isDisabled?: boolean
  scriptType?: ScriptType
  onChangeText?: (text: string) => void
}

// A 'fake' input
// This is a component that looks like an input
// But brings up a date picker or action sheet when pressed
export const InputFieldFake = React.memo(
  ({
    placeholder,
    value,
    focus,
    type = 'input',
    variant = 'underlined',
    textAlign = 'left',
    onPress,
    isInvalid,
    width = '100%',
    inputRef,
    isReadOnly,
    isDisabled,
    scriptType,
    onChangeText,
  }: InputFieldFakeProps) => {
    const hasUnderline = variant === 'underlined'
    const textAlignLeft = textAlign === 'left'

    const backgroundColor = useColorModeValue(WHITE, BLACK)
    const color = useColorModeValue(BLACK, WHITE)
    const inactiveColor = useColorModeValue(
      PLACEHOLDER_COLOR_LIGHT,
      PLACEHOLDER_COLOR_DARK,
    )
    const borderBottomColor = color
    const inactiveBorderColor = color

    return type === 'textInput' ? (
      <TextInput
        inputRef={inputRef}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        isRequired={true}
        onFocus={onPress}
        placeholder={placeholder}
        value={value}
        w={width}
        onChangeText={onChangeText}
        isReadOnly={isReadOnly}
        // @ts-ignore
        style={{
          ...Platform.select({
            web: {
              cursor: 'pointer',
            },
          }),
        }}
        borderBottomColor={
          focus || value ? borderBottomColor : inactiveBorderColor
        }
      />
    ) : type === 'text' ? (
      <Row m={0} p={0} alignItems={'center'}>
        <Button variant={'unstyled'} onPress={onPress} m={0} p={0}>
          <SansText>{value}</SansText>
        </Button>
      </Row>
    ) : (
      <Box px={0} position={'relative'} width={'100%'} height={10}>
        <Button.Group
          variant="unstyled"
          flex={1}
          alignSelf="flex-start"
          isAttached
          width="100%"
        >
          <Button
            isDisabled={isDisabled}
            w={type === 'select' ? '90%' : '100%'}
            onPress={onPress}
            height={10}
            px={0}
            mx={0}
            variant="unstyled"
            borderBottomColor={
              focus || value ? borderBottomColor : inactiveBorderColor
            }
            _pressed={{
              opacity: 0.5,
            }}
            _text={{
              color: focus ? borderBottomColor : inactiveColor,
            }}
            style={{
              borderBottomWidth: hasUnderline ? LINE_WIDTH : 0,
              borderBottomLeftRadius: 0,
              marginHorizontal: 0,
              paddingHorizontal: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: textAlignLeft ? 'flex-start' : 'center',
            }}
          >
            <SansText
              pl={0.5}
              height={10}
              color={value ? color : inactiveColor}
              textAlign={textAlign}
              flex={1}
              alignSelf="flex-end"
              scriptType={scriptType}
            >
              {value || placeholder}
            </SansText>
          </Button>
          {type === 'select' ? (
            <Button
              _pressed={{
                opacity: 0.5,
              }}
              onPress={onPress}
              w={'10%'}
              h={10}
              bg={backgroundColor}
              variant="unstyled"
              borderBottomColor={
                focus || value ? borderBottomColor : inactiveBorderColor
              }
              style={{
                borderBottomWidth: hasUnderline ? LINE_WIDTH : 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                marginHorizontal: 0,
                paddingHorizontal: 0,
              }}
            >
              <IconCaretDown style={{ alignSelf: 'flex-end' }} color={color} />
            </Button>
          ) : (
            <></>
          )}
        </Button.Group>
      </Box>
    )
  },
)
