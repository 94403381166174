import React from 'react'
import { BLACK, MOON_PHASE_MAP, WHITE } from '../../../constants/ui-constants'
import { Row } from '../../common/row/row'
import { FastTag } from '../../../../../api/_openapi'
import { Hemisphere, MoonPhase } from '../../../../../api/frontend-types'
import { Moon, Hemisphere as LunarHemisphere, LunarPhase } from 'lunarphase-js'
import { camelCase } from '../../../modules/strings/string-helpers'
import { useColorModeValue, useColorMode } from 'native-base'
import { IconMoonPhase } from '../../../assets/react-native-svg/icons/icons-moon-phase'
import { useSelector } from '../../../ducks/root-reducer'
import {
  selectUserIsPremium,
  selectUserIsSupporter,
} from '../../../ducks/user/user'
import { useHemisphere } from '../../../hooks/useHemisphere'

const getMoonPhase = (date: Date, hemisphere: Hemisphere) => {
  const phase = Moon.lunarPhase(date, {
    hemisphere: hemisphere as LunarHemisphere,
  })
  return camelCase(
    phase === LunarPhase.NEW
      ? 'New Moon'
      : phase === LunarPhase.FULL
      ? 'Full Moon'
      : phase,
  ) as MoonPhase
}

type MoonPhaseTagProps = {
  moonPhaseTag: (Omit<FastTag, 'type'> & { type: 'MOON_PHASE' }) | null
  date?: never
}

type HemisphereDateProps = {
  moonPhaseTag?: never
  date: Date
}

type AstrologicalDataProps = (MoonPhaseTagProps | HemisphereDateProps) & {
  mt?: number
  size?: number
}

const AstrologicalData = ({
  moonPhaseTag,
  date,
  mt = 2,
  size = 30,
}: AstrologicalDataProps) => {
  const hemisphere = useHemisphere()
  if (!moonPhaseTag && !hemisphere && !date) return null
  let moonPhase: MoonPhase | undefined
  if (hemisphere && date) {
    moonPhase = getMoonPhase(date, hemisphere)
  } else if (moonPhaseTag) {
    moonPhase = moonPhaseTag.name.split('.')[1] as MoonPhase
  }
  const color = useColorModeValue(BLACK, WHITE)
  const { colorMode } = useColorMode()
  const userIsPremium = useSelector(selectUserIsPremium)
  const userIsSupporter = useSelector(selectUserIsSupporter)

  if (!userIsSupporter) {
    return null
  }

  return (
    <Row justifyContent={'center'} marginTop={mt || 0}>
      {/* {Object.keys(MOON_PHASE_MAP.dark).map((moonPhase) => {
        return (
          <Tooltip label={t(`moonphase.${moonPhase}`)} placement={'right'}> */}
      <IconMoonPhase
        iconKey={moonPhase as MoonPhase}
        color={color}
        hemisphere={hemisphere}
        size={size}
      />
      {/* </Tooltip>
        )
      })} */}
    </Row>
  )
}

export { AstrologicalData }
