import React, { useEffect, useState, useMemo } from 'react'
import { useColorModeValue } from 'native-base'
import { Loading } from '../../../components/layout/loading'
import { ChartLegend } from './chart-legend'
import { ChartTitleText } from './chart-title'
import { useTranslation } from 'react-i18next'
import { bucketizeChartData } from './bucketize'
import { NoContentMessage } from '../../layout/no-content-message'
import { getYear, monthDayFormat, monthYearFormat } from './date-formatters'
import { SANS_BOOK_FONT_WEIGHT } from '../../../constants/constants'
import i18n from '../../../i18n/i18nnext'
import { getSansFont } from '../../../modules/language-helpers/language-helpers'
import {
  DateIntervalKey,
  DateRangeKey,
  DateRangeWithKey,
  LanguageDirection,
} from '../../../../../api/frontend-types'
import {
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
} from 'victory-native'
import {
  CREAM,
  DARK_CREAM,
  LIGHTER_BLACK,
  LIGHT_BLACK,
  LIGHT_MID_GRAY,
} from '../../../constants/ui-constants'
import { reverseArray } from '../../../modules/data-structure-helpers/data-structure-helpers'
import { Box, InterfaceBoxProps } from '../../common/box/box'

export type ChartDataArr = { x: string; y: number }[]
export type LineData = { data: ChartDataArr; color: string; label: string }
export type ChartData = { [key: string]: LineData }
export type BucketizeMode = 'average' | 'sum'

export type ChartPadding = {
  bottom?: number
  left?: number
  right?: number
  top?: number
}

type TimelineChartProps = InterfaceBoxProps & {
  chartData: ChartData | null
  loading: boolean
  chartPadding: ChartPadding
  tickCount: number
  dateRangeWithKey: DateRangeWithKey
  yTickFormat?: (y: number, index?: number) => string
  chartTitle?: string
  userLanguage?: string
  bucketizeMode?: BucketizeMode
  noDataMessage?: string
  getChartTitle?: (dateRangeKey: DateIntervalKey) => string
}

export const TimelineChart = ({
  chartData,
  loading,
  chartPadding,
  tickCount,
  chartTitle,
  dateRangeWithKey,
  userLanguage = 'en-US',
  bucketizeMode = 'average',
  getChartTitle,
  noDataMessage,
  yTickFormat = (y: number, _index?: number) => {
    return y.toString()
  },
  ...boxProps
}: TimelineChartProps) => {
  const [dateInterval, setDateInterval] = useState<DateIntervalKey>('WEEK')

  // HOOKS
  const { t } = useTranslation()
  const chartBackgroundColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const gridColor = useColorModeValue(DARK_CREAM, LIGHTER_BLACK)
  const tickLabelColor = useColorModeValue(LIGHT_MID_GRAY, LIGHT_MID_GRAY)

  // VARS
  const displayTitle = chartTitle || getChartTitle?.(dateInterval) || null
  const defaultNoDataMessage = t('chartsPage.noDataInRangeMessage')
  const noDataMessageToUse = noDataMessage || defaultNoDataMessage

  // I18N
  const sansFontFamily = getSansFont(userLanguage)
  const dir = i18n.dir(userLanguage)
  const axisOrientation = dir === 'rtl' ? 'right' : 'left'

  const bucketizedData = useMemo(() => {
    if (!chartData) {
      return null
    }
    const bucketized = bucketizeChartData(
      chartData,
      dateInterval,
      dateRangeWithKey,
      bucketizeMode,
      displayTitle || '',
    )
    return dataWithDirection(bucketized, dir)
  }, [
    chartData,
    dateInterval,
    dateRangeWithKey,
    bucketizeMode,
    displayTitle,
    dir,
  ])

  // Get date interval for date range key
  useEffect(() => {
    if (!chartData) {
      return
    }
    const interval = getDateIntervalForDateRangeKey(
      dateRangeWithKey.key,
      chartData,
    )
    setDateInterval(interval)
  }, [dateRangeWithKey, chartData])

  // FUNCTIONS
  function xTickFormat(x: string, index?: number): string {
    // Only show every other tick
    if (index !== undefined && index % 2 === 0) {
      return ''
    }

    switch (dateInterval) {
      case 'DAY':
        const day = new Date(x)
        return monthDayFormat(day, userLanguage)
      case 'WEEK':
        // Turn number into 'x weeks ago'
        const xNumber = parseInt(x)
        return '-' + t('common.weeks', { count: Math.abs(xNumber) })
      case 'MONTH':
        const d = new Date(x)
        return monthYearFormat(d, userLanguage)
      case 'YEAR':
        const dateForYear = new Date(x)
        return getYear(dateForYear, userLanguage)
      default:
        return `${x}`
    }
  }

  // VARS
  const chartBottomPadding =
    chartPadding.bottom || getBottomPadding(dateInterval, userLanguage)

  const padding = {
    ...chartPadding,
    bottom: chartBottomPadding,
  }
  const noData =
    !bucketizedData ||
    Object.keys(bucketizedData).length === 0 ||
    Object.values(bucketizedData).every((value) => value.data.length === 0)

  if (loading) {
    return <Loading mt={20} />
  }

  return (
    <Box width={'100%'} {...boxProps}>
      {displayTitle && (
        <ChartTitleText
          mt={2}
          // Align title with chart
          // paddingLeft={chartPadding.left || 0}
          // paddingRight={chartPadding.right || 0}
          width={'100%'}
          style={{
            textAlign: 'center',
          }}
        >
          {displayTitle}
        </ChartTitleText>
      )}
      {
        // No data
        noData && (
          <NoContentMessage message={noDataMessageToUse} mt={8} pb={16} />
        )
      }
      {!noData && bucketizedData && (
        <Box>
          <VictoryChart
            theme={VictoryTheme.grayscale}
            style={{
              background: {
                fill: chartBackgroundColor,
              },
            }}
            domainPadding={{ x: 15, y: 0 }}
            padding={padding}
          >
            <VictoryAxis
              style={{
                tickLabels: { fontSize: 10 },
                grid: { stroke: gridColor },
                axis: {
                  stroke: gridColor,
                },
              }}
              orientation="bottom"
              offsetY={chartBottomPadding}
              tickCount={tickCount}
              tickFormat={(x: string, i: number) => {
                return xTickFormat(x, i)
              }}
              // 45 degree angle
              tickLabelComponent={
                <VictoryLabel
                  angle={-45}
                  textAnchor={'end'}
                  style={{
                    padding: 50,
                    fill: tickLabelColor,
                    fontSize: 11,
                    fontFamily: sansFontFamily,
                    fontWeight: SANS_BOOK_FONT_WEIGHT,
                  }}
                />
              }
              // Align right (so that the last tick is at the end of the chart)
            />
            {/* y axis */}
            <VictoryAxis
              dependentAxis
              orientation={axisOrientation}
              tickFormat={yTickFormat}
              domain={calculateDomain(bucketizedData, displayTitle || '')}
              crossAxis={false}
              style={{
                tickLabels: { fontSize: 10 },
                grid: { stroke: gridColor },
                axis: {
                  stroke: gridColor,
                },
              }}
              tickLabelComponent={
                <VictoryLabel
                  style={{
                    fill: tickLabelColor,
                    fontSize: 11,
                    fontFamily: sansFontFamily,
                    fontWeight: SANS_BOOK_FONT_WEIGHT,
                  }}
                />
              }
            />
            {Object.entries(bucketizedData).map(([key, value]) => {
              return (
                <VictoryLine
                  interpolation="linear"
                  key={key}
                  data={value.data}
                  style={{
                    data: {
                      stroke: value.color as unknown as string,
                      strokeLinejoin: 'round',
                      strokeLinecap: 'round',
                      strokeWidth: 1.5,
                    },
                  }}
                />
              )
            })}
          </VictoryChart>
          <ChartLegend
            items={Object.values(bucketizedData).map((value) => {
              return {
                label: value.label,
                color: value.color,
              }
            })}
          />
        </Box>
      )}
    </Box>
  )
}

// We want both min and max on the domain to be a multiple of 0.1
// And both to be ~10% larger than the min/max
function calculateDomain(
  data: ChartData,
  displayTitle: string,
): [number, number] {
  // 1) Get min/max
  const allData = Object.values(data).flatMap((d) => d.data)
  const yValues = allData.map((d) => d.y)
  const min = Math.min(...yValues)
  const max = Math.max(...yValues)

  // 2) Add 'padding'
  const minMaxDiff = Math.abs(max - min)
  const padding = minMaxDiff / 5
  const minWithPadding = min - padding
  const maxWithPadding = max + padding

  // 3) Rounding
  let minRounded = minWithPadding
  let maxRounded = maxWithPadding

  // If numbers are < 1, round to 1 decimal place
  if (Math.abs(minWithPadding) < 1 && Math.abs(maxWithPadding) < 1) {
    minRounded = Math.floor(minWithPadding * 10) / 10
    maxRounded = Math.ceil(maxWithPadding * 10) / 10
    if (minRounded === maxRounded) {
      minRounded -= 0.1
      maxRounded += 0.1
    }
  }
  // If numbers are < 10, round to nearest integer
  else if (Math.abs(minWithPadding) < 10 && Math.abs(maxWithPadding) < 10) {
    minRounded = Math.floor(minWithPadding)
    maxRounded = Math.ceil(maxWithPadding)
    if (minRounded === maxRounded) {
      minRounded -= 1
      maxRounded += 1
    }
  }
  // If numbers are < 50, round to nearest 5
  else if (Math.abs(minWithPadding) < 50 && Math.abs(maxWithPadding) < 50) {
    minRounded = Math.floor(minWithPadding / 5) * 5
    maxRounded = Math.ceil(maxWithPadding / 5) * 5
    if (minRounded === maxRounded) {
      minRounded -= 5
      maxRounded += 5
    }
  }
  // If numbers are < 100, round to nearest 10
  else if (Math.abs(minWithPadding) < 100 && Math.abs(maxWithPadding) < 100) {
    minRounded = Math.floor(minWithPadding / 10) * 10
    maxRounded = Math.ceil(maxWithPadding / 10) * 10
    if (minRounded === maxRounded) {
      minRounded -= 10
      maxRounded += 10
    }
  }
  // If numbers are < 500, round to nearest 50
  else if (Math.abs(minWithPadding) < 500 && Math.abs(maxWithPadding) < 500) {
    minRounded = Math.floor(minWithPadding / 50) * 50
    maxRounded = Math.ceil(maxWithPadding / 50) * 50
    if (minRounded === maxRounded) {
      minRounded -= 50
      maxRounded += 50
    }
  }
  // If numbers are < 1000, round to nearest 100
  else if (Math.abs(minWithPadding) < 1000 && Math.abs(maxWithPadding) < 1000) {
    minRounded = Math.floor(minWithPadding / 100) * 100
    maxRounded = Math.ceil(maxWithPadding / 100) * 100
    if (minRounded === maxRounded) {
      minRounded -= 100
      maxRounded += 100
    }
  }

  const domain: [number, number] = [minRounded, maxRounded]
  return domain
}

// Some languages need more padding on the bottom
function getBottomPadding(dateRangeKey: DateIntervalKey, lang: string) {
  return 80
}

export function getDateIntervalForDateRangeKey(
  dateRangeKey: DateRangeKey,
  chartData: ChartData,
): DateIntervalKey {
  switch (dateRangeKey) {
    case 'LAST_TWO_WEEKS':
      return 'DAY'
    case 'LAST_THREE_MONTHS':
      return 'WEEK'
    case 'LAST_YEAR':
      return 'MONTH'
    case 'ALL_TIME':
      // TODO
      return 'YEAR'
    default:
      return 'MONTH'
  }
}

// Reverse data for RTL languages
function dataWithDirection(
  chartData: ChartData,
  dir: LanguageDirection,
): ChartData {
  if (dir === 'rtl') {
    const reversedData: ChartData = {}
    for (const [key, value] of Object.entries(chartData)) {
      reversedData[key] = {
        ...value,
        data: reverseArray(value.data),
      }
    }
    return reversedData
  }
  return chartData
}
