import React, { useMemo, useCallback, useState } from 'react'
import { OpenAPI } from '../../../../../api/_openapi'
import { useColorModeValue } from 'native-base'
import { PaddedContentArea } from '../../../components/layout/content-area-padded'
import { ButtonPill } from '../../../components/common/buttons/button-pill'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../ducks/root-reducer'
import { uuidv7 } from 'uuidv7'
import { Box } from '../../../components/common/box/box'
import { SansText } from '../../../components/common/copy/text-sans'
import { putDownloadCodeOnUser } from '../../../ducks/user/thunks/user-thunks'
import {
  selectExportLink,
  selectUser,
  setExportLink,
} from '../../../ducks/user/user'
import { Linking } from 'react-native'
import {
  DownloadFormat,
  DateRangeWithKey,
} from '../../../../../api/frontend-types'
import {
  shallowEqualsExportLink,
  shallowEqualsUser,
} from '../../../ducks/shallowEqual'
import { useTranslation } from 'react-i18next'
import { DateRangeSelect } from '../../../components/composite/chart/date-range-select'
import { dateRangeFromKey } from '../../Charts/chart-helpers'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { SelectWithActionSheet } from '../../../components/common/inputs/select/select-with-action-sheet'
import {
  cap,
  formatDateForDatabase,
} from '../../../modules/strings/string-helpers'
import { BodyText } from '../../../components/common/copy/text-body'
import { HeadingSans } from '../../../components/common/copy/heading-sans'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import { ScrollView } from '../../../components/common/scrollview/scrollview'

export type DataExportNavigationProp =
  RootStackNavigationProp<'PrivateDataExport'>

export default function DataExport({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDataExport'>) {
  // STATE
  const [dateRangeWithKey, setDateRangeWithKey] = useState<DateRangeWithKey>({
    key: 'ALL_TIME',
    range: dateRangeFromKey('ALL_TIME'),
  })
  const [exportType, setExportType] = useState<DownloadFormat>('txt')

  const dispatch = useDispatch<any>()
  const { t } = useTranslation()

  const user = useSelector(selectUser, shallowEqualsUser)
  const exportLink = useSelector(selectExportLink, shallowEqualsExportLink)

  const downloadUrl = useMemo(() => {
    const hasCurrentLink =
      exportLink && exportLink.expiry && exportLink.expiry > Date.now()
    const url: string = hasCurrentLink
      ? `${OpenAPI.BASE}/export/dreams/${exportLink.format}?code=${
          exportLink.code
        }${
          dateRangeWithKey.range.start
            ? '&startDate=' +
              formatDateForDatabase(dateRangeWithKey.range.start)
            : ''
        }${
          dateRangeWithKey.range.end
            ? '&endDate=' + formatDateForDatabase(dateRangeWithKey.range.end)
            : ''
        }`
      : ``
    return url
  }, [exportLink, dateRangeWithKey, exportType])

  const handlePress = useCallback(async (format: DownloadFormat) => {
    // First, generate a UUID for this export
    const downloadCode = uuidv7()
    dispatch(
      setExportLink({
        code: downloadCode,
        format: format,
        loading: true,
      }),
    )
    // Then PUT the exportId to the user's account
    dispatch(putDownloadCodeOnUser(user?.id || '', format, downloadCode))
  }, [])

  const loading = exportLink?.loading || false
  const backgroundColor = useColorModeValue(WHITE, BLACK)

  return (
    <ScrollView width="100%" height="100%">
      <PaddedContentArea>
        {[
          'dataExportPage.description.paragraph1',
          'dataExportPage.description.paragraph2',
          'dataExportPage.description.paragraph3',
        ].map((paragraph, index) => {
          return (
            <BodyText mt={4} key={index}>
              {t(paragraph)}
            </BodyText>
          )
        })}
        <Box
          style={{
            width: '100%',
            backgroundColor,
            maxWidth: WEB_MAX_WIDTH,
            alignItems: 'center',
            padding: 16,
          }}
          mt={4}
          pl={1}
          pr={1}
        >
          <HeadingSans mb={2}>{t('dataExportPage.exportType')}</HeadingSans>
          <SelectWithActionSheet
            options={['json', 'csv', 'txt', 'html'].map((option) => ({
              label: option.toLocaleUpperCase(),
              scriptType: 'latin',
              value: option,
            }))}
            onChange={(option: any) => {
              setExportType(option.value as DownloadFormat)
            }}
            value={exportType as string}
            searchable={false}
            heading={t('dataExportPage.exportType')}
          />
        </Box>
        <Box
          style={{
            width: '100%',
            backgroundColor,
            maxWidth: WEB_MAX_WIDTH,
            alignItems: 'center',
            padding: 16,
          }}
          mt={4}
          mb={4}
          pl={1}
          pr={1}
        >
          <HeadingSans mb={2}>{t('dataExportPage.dateRange')}</HeadingSans>
          <DateRangeSelect
            onChange={(dateRangeWithKey: any) => {
              setDateRangeWithKey(dateRangeWithKey)
            }}
            value={dateRangeWithKey}
            includeCustom={true}
          />
        </Box>
        <ButtonPill
          isLoading={loading && exportLink?.format === exportType}
          mt={10}
          onPress={() => {
            handlePress(exportType as Exclude<DownloadFormat, null>)
          }}
        >
          {t(`dataExportPage.buttons.generate${cap(exportType)}Export`)}
        </ButtonPill>
        {downloadUrl !== '' && exportLink !== undefined && !loading && (
          <SansText
            textDecorationLine={'underline'}
            mt={10}
            textAlign={'center'}
            onPress={() => {
              Linking.openURL(downloadUrl)
            }}
          >
            {t('dataExportPage.downloadLink', {
              format: exportLink.format.toLocaleUpperCase(),
            })}
          </SansText>
        )}
      </PaddedContentArea>
    </ScrollView>
  )
}
