import * as React from 'react'
import { iconSizes, Size } from '../../../core/theme'
import { ResponsiveValue } from 'native-base/lib/typescript/components/types'
import { IColors } from 'native-base/lib/typescript/theme/base/colors'
import { ColorValue } from 'react-native'
import { TagIcon } from './tag-icon'
import { TagType } from '../../../../../api/frontend-types'
import { TagState } from './tag-pill'
import { Friend } from '../../../../../api/_openapi'
import {
  IconButton,
  IconButtonProps,
} from '../../common/icon-button/icon-button'

export type ButtonIconProps = IconButtonProps & {
  iconKey: Exclude<TagType, 'MOON_PHASE'>
  friend: Friend | undefined
  size?: Size
  iconFill?: ColorValue
  iconStroke?: ResponsiveValue<IColors | (string & {})>
  outlined?: boolean
  tagState: TagState
}

// This is really not much different to our custom ButtonIcon component
// But the SVGs are structured differently
// so it's cleaner to make a new component
export const TagButtonIcon = ({
  iconKey,
  friend,
  outlined,
  iconFill,
  iconStroke,
  size,
  tagState,
  ...rest
}: ButtonIconProps) => {
  // If we're editing, we show a button
  // Otherwise we just show the icon
  const shouldBeButton = tagState === 'editing'

  // We need the button size to be the same as the icon size
  // Since the outline is built into the outlined SVG
  const iconSize: Size = size || 'md'
  const buttonSize = iconSizes[iconSize]

  if (shouldBeButton) {
    return (
      <IconButton
        icon={
          <TagIcon
            friend={friend}
            iconKey={iconKey}
            color={iconStroke}
            fill={iconFill}
            size={iconSize}
          />
        }
        borderRadius={'full'}
        borderColor={iconStroke}
        borderWidth={1}
        size={buttonSize}
        p={0}
        {...rest}
      />
    )
  } else {
    return (
      <TagIcon
        friend={friend}
        borderRadius={'full'}
        iconKey={iconKey}
        color={iconStroke}
        fill={iconFill}
        size={iconSize}
      />
    )
  }
}
