import { useCallback, useMemo } from 'react'
import { useSelector } from '../ducks/root-reducer'
import { selectCurrentUserProfile, selectUser } from '../ducks/user/user'
import { Hemisphere } from '../../../api/frontend-types'

const calculateHemisphere = (userProfile: any): Hemisphere => {
  // TODO: This is a temporary solution to get the hemisphere based on the country code.
  // We should use the user's location to get the hemisphere.
  const worldCountries = {
    Northern: [
      'AD',
      'AE',
      'AF',
      'AG',
      'AI',
      'AL',
      'AM',
      'AT',
      'AW',
      'AX',
      'AZ',
      'BA',
      'BB',
      'BD',
      'BE',
      'BF',
      'BG',
      'BH',
      'BJ',
      'BL',
      'BM',
      'BN',
      'BQ',
      'BS',
      'BT',
      'BY',
      'BZ',
      'CA',
      'CF',
      'CH',
      'CI',
      'CM',
      'CN',
      'CO',
      'CR',
      'CU',
      'CV',
      'CW',
      'CY',
      'CZ',
      'DE',
      'DJ',
      'DK',
      'DM',
      'DO',
      'DZ',
      'EE',
      'EG',
      'EH',
      'ER',
      'ES',
      'ET',
      'FI',
      'FM',
      'FO',
      'FR',
      'GB',
      'GD',
      'GE',
      'GG',
      'GH',
      'GI',
      'GL',
      'GM',
      'GN',
      'GP',
      'GQ',
      'GR',
      'GT',
      'GU',
      'GW',
      'GY',
      'HK',
      'HN',
      'HR',
      'HT',
      'HU',
      'IE',
      'IL',
      'IM',
      'IN',
      'IQ',
      'IR',
      'IS',
      'IT',
      'JE',
      'JM',
      'JO',
      'JP',
      'KG',
      'KH',
      'KI',
      'KN',
      'KP',
      'KR',
      'KW',
      'KY',
      'KZ',
      'LA',
      'LB',
      'LC',
      'LI',
      'LK',
      'LR',
      'LT',
      'LU',
      'LV',
      'LY',
      'MA',
      'MC',
      'MD',
      'ME',
      'MF',
      'MH',
      'MK',
      'ML',
      'MM',
      'MN',
      'MO',
      'MP',
      'MQ',
      'MR',
      'MS',
      'MT',
      'MV',
      'MX',
      'MY',
      'NE',
      'NG',
      'NI',
      'NL',
      'NO',
      'NP',
      'OM',
      'PA',
      'PH',
      'PK',
      'PL',
      'PM',
      'PR',
      'PS',
      'PT',
      'PW',
      'QA',
      'RO',
      'RS',
      'RU',
      'SA',
      'SD',
      'SE',
      'SG',
      'SI',
      'SJ',
      'SK',
      'SL',
      'SM',
      'SN',
      'SO',
      'SR',
      'SS',
      'ST',
      'SV',
      'SX',
      'SY',
      'TC',
      'TD',
      'TG',
      'TH',
      'TJ',
      'TM',
      'TN',
      'TR',
      'TT',
      'TW',
      'UA',
      'UG',
      'UM',
      'US',
      'UZ',
      'VA',
      'VC',
      'VE',
      'VG',
      'VI',
      'VN',
      'YE',
    ],
    Southern: [
      'AO',
      'AQ',
      'AR',
      'AS',
      'AU',
      'BI',
      'BO',
      'BR',
      'BV',
      'BW',
      'CC',
      'CD',
      'CG',
      'CK',
      'CL',
      'CX',
      'EC',
      'FJ',
      'FK',
      'GA',
      'GF',
      'GS',
      'HM',
      'ID',
      'IO',
      'KE',
      'KM',
      'LS',
      'MG',
      'MU',
      'MW',
      'MZ',
      'NA',
      'NC',
      'NF',
      'NR',
      'NU',
      'NZ',
      'PE',
      'PF',
      'PG',
      'PN',
      'PY',
      'RE',
      'RW',
      'SB',
      'SC',
      'SH',
      'SZ',
      'TF',
      'TK',
      'TL',
      'TO',
      'TV',
      'TZ',
      'UY',
      'VU',
      'WF',
      'WS',
      'YT',
      'ZA',
      'ZM',
      'ZW',
    ],
  }

  if (!userProfile?.country) {
    return 'Northern'
  }

  if (worldCountries.Northern.includes(userProfile.country)) {
    return 'Northern'
  } else if (worldCountries.Southern.includes(userProfile.country)) {
    return 'Southern'
  } else {
    return 'Northern'
  }
}

export const useHemisphere = () => {
  const user = useSelector(selectUser)
  const userProfile = useSelector(selectCurrentUserProfile)

  const hemisphere = useMemo(() => {
    const hemispheresMap: { [key: string]: Hemisphere } = {
      n: 'Northern',
      s: 'Southern',
    }

    return user?.hemisphere === null && userProfile
      ? calculateHemisphere(userProfile)
      : hemispheresMap[user?.hemisphere as keyof typeof hemispheresMap] ||
          'Northern'
  }, [user?.hemisphere, userProfile?.country])

  return hemisphere
}
