import React from 'react'
import Autolink from 'react-native-autolink'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { SubscriptionsNavigationProp } from '../../../screens/Settings/Subscriptions'
import { AlertPopup } from '../../common/dialog/alert-dialog'
import { title } from '../../../modules/strings/title-case'
import { SansText } from '../../common/copy/text-sans'
import { SANS_BOLD_FONT_WEIGHT } from '../../../constants/constants'
import { useColorModeValue } from 'native-base'
import { IconGem } from '../../../assets/react-native-svg/icons/icons-ui'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { Row } from '../../common/row/row'

type PleaseSubscribeDialogProps = {
  isOpen: boolean
  onClose: () => void
  onConfirmAdditionalAction?: () => void
  includeCreditsLink?: boolean
}

export const PleaseSubscribeDialog = ({
  isOpen,
  onClose,
  onConfirmAdditionalAction,
  includeCreditsLink = true,
}: PleaseSubscribeDialogProps) => {
  // HOOKS
  const { t } = useTranslation()
  const navigation = useNavigation<SubscriptionsNavigationProp>()
  const textColor = useColorModeValue(BLACK, WHITE)

  return (
    <AlertPopup
      isOpen={isOpen}
      headerComponent={
        <Row alignItems="center" pr={5}>
          <IconGem size={'xs'} color={textColor} marginX={3} />
          <SansText fontWeight={SANS_BOLD_FONT_WEIGHT}>
            {title(t('common.premiumFeature'))}
          </SansText>
        </Row>
      }
      descriptionElement={
        <SansText>
          <Autolink
            text={t('subscriptions.subscribeToUnlock') as string}
            matchers={[
              {
                pattern: /<linkTag>(.*)<\/linkTag>/g,
                getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                onPress: () => {
                  onConfirmAdditionalAction && onConfirmAdditionalAction()
                  navigation.navigate('PrivateSubscriptionSettingsEdit', {
                    tab: 'premium',
                  })
                },
              },
            ]}
            linkProps={{
              style: {
                textDecorationLine: 'underline',
              },
            }}
          />{' '}
          {includeCreditsLink && (
            <Autolink
              text={t('subscriptions.orCreditsToUnlock') as string}
              matchers={[
                {
                  pattern: /<linkTag>(.*)<\/linkTag>/g,
                  getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
                  onPress: () => {
                    onConfirmAdditionalAction && onConfirmAdditionalAction()
                    navigation.navigate('PrivateCreditSettingsEdit')
                  },
                },
              ]}
              linkProps={{
                style: {
                  textDecorationLine: 'underline',
                },
              }}
            />
          )}
        </SansText>
      }
      confirmLabel={t('common.subscribe')}
      onConfirm={() => {
        onConfirmAdditionalAction && onConfirmAdditionalAction()
        navigation.navigate('PrivateSubscriptionSettingsEdit', {
          tab: 'premium',
        })
      }}
      onClose={onClose}
    />
  )
}
